import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, TemplateRef } from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { MapItem, ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

export interface SelectListItem extends MapItem {
  disabled?: boolean;
  subtitle?: string;

  [key: string]: any;
}

@Component({
  selector: 'app-select-list-item',
  templateUrl: './select-list-item.component.html',
  styleUrls: ['./select-list-item.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(SelectListItemComponent)],
})
export class SelectListItemComponent extends ValueAccessorBase<string> implements AfterViewInit {
  @Input() public items: SelectListItem[];
  @Input() public itemGroupName = 'items';
  @Input() public isReadOnly: boolean;
  @Input() public labelTranslationKey = '';
  @Input() public noActiveItemsTranslationKey = '';
  @Input() public disabledItemTranslationKey = '';
  @Input() public leftIcon = 'fas fa-ban';
  @Input() public rightIcon = 'far fa-chevron-right';
  @Input() public showRightIcon: boolean;
  @Input() public customLeftIcon: TemplateRef<any>;

  @Output() public itemClick: EventEmitter<SelectListItem> = new EventEmitter<SelectListItem>();

  public control: UntypedFormControl;

  constructor(
    private injector: Injector,
    private validatorService: ValidatorService,
  ) {
    super();
  }

  public selectItem(item: SelectListItem): void {
    if (!!item && !item.disabled && item.hasOwnProperty('value')) {
      this.value = item.value;

      this.itemClick.emit(item);
    }
  }

  public trackByFn(index: number, item: SelectListItem): string {
    return item.value;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);

      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
}
