<button
  [disabled]="disabled"
  class="cap-button p-button secondary secondary-button primary-outline px-3 {{ styleClass }}">
  @if (!!icon || !!label) {
    @if (icon) {
      <i class="{{ icon }}"></i>
    }
    <span>{{ label }}</span>
  } @else {
    <ng-content></ng-content>
  }
</button>
