import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subString',
})
export class SubStringPipe implements PipeTransform {
  public transform(value: string, character: string): string {
    if (value && value.indexOf(character) !== -1) {
      return value.substr(0, value.indexOf(character));
    }

    return value;
  }
}
