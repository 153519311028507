import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoTableComponent } from './info-table.component';
import { TableModule } from 'primeng/table';
import { PropertyValuePipe } from './pipes/property-value.pipe';
import { ColumnTemplatePipe } from './pipes/column-template.pipe';
import { CapturumSharedModule } from '@capturum/ui/api';

const BASE_PIPES = [ColumnTemplatePipe, PropertyValuePipe];

@NgModule({
  declarations: [InfoTableComponent, ...BASE_PIPES],
  exports: [InfoTableComponent, ...BASE_PIPES],
  imports: [CommonModule, TableModule, CapturumSharedModule],
})
export class InfoTableModule {}
