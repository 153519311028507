<div class="content">
  <!-- CONTENT BODY -->
  @if (!isMobile) {
    <div class="content-body d-none d-md-block">
      <div class="row">
        @if (firstTemplate) {
          <div class="col col-md-6 col-xl-4 detail-column">
            @if (navTabItems && navTabItems[0]) {
              <h2 class="mb-4 bold">{{ navTabItems[0] }}</h2>
            }
            <ng-container [ngTemplateOutlet]="firstTemplate"></ng-container>
          </div>
        }
        @if (secondTemplate) {
          <div class="col col-md-6 col-xl-4 detail-column" [class.no-border]="noBorder">
            @if (navTabItems && navTabItems[1]) {
              <h2 class="mb-4 bold">{{ navTabItems[1] }}</h2>
            }
            <ng-container [ngTemplateOutlet]="secondTemplate"></ng-container>
          </div>
        }
        @if (thirdTemplate) {
          <div class="col col-md-6 col-xl-4 detail-column" [class.no-border]="noBorder">
            @if (navTabItems && navTabItems[2]) {
              <h2 class="mb-4 bold">{{ navTabItems[2] }}</h2>
            }
            <ng-container [ngTemplateOutlet]="thirdTemplate"></ng-container>
          </div>
        }
      </div>
    </div>
  }

  <!-- MOBILE CONTENT -->
  @if (isMobile) {
    <div class="content-mobile d-sm-block d-md-none d-lg-none d-xl-none">
      <div appSubMenuTabContent [navTabIndex]="0">
        <div class="row p-3">
          <ng-container [ngTemplateOutlet]="firstTemplate"></ng-container>
        </div>
        @if (buttonsTemplate) {
          <div class="row p-3">
            <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
          </div>
        }
      </div>
      <div appSubMenuTabContent [navTabIndex]="1">
        @if (secondTemplate) {
          <div class="row p-3">
            <ng-container [ngTemplateOutlet]="secondTemplate"></ng-container>
          </div>
        }
      </div>
      <div appSubMenuTabContent [navTabIndex]="2">
        @if (thirdTemplate) {
          <div class="row p-3">
            <ng-container [ngTemplateOutlet]="thirdTemplate"></ng-container>
          </div>
        }
      </div>
    </div>
  }
</div>
