import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-horizontal-line-with-text',
  templateUrl: './horizontal-line-with-text.component.html',
  styleUrls: ['./horizontal-line-with-text.component.scss'],
})
export class HorizontalLineWithTextComponent {
  @Input() public title: string;
  @Input() public badge?: number | string;
}
