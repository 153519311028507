import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionMenu } from '@core/models/actionMenu.model';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuComponent {
  @Input()
  public styleClass: string;

  public classes = '';
  public isOpen = false;
  public permissions: string[];

  private _actionItems: ActionMenu[] = [];

  public get actionItems(): ActionMenu[] {
    return this._actionItems;
  }

  @Input()
  public set actionItems(value: ActionMenu[]) {
    this.permissions = value.reduce((permissions, item) => {
      return item?.permissions ? [...permissions, ...item.permissions] : permissions;
    }, []);

    this._actionItems = value;
  }

  public toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  public executeAction(action: ActionMenu): void {
    if (action.disabled) {
      return;
    }

    this.isOpen = false;
    action.clickCallback();
  }
}
