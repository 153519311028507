import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-accordion-top-header',
  templateUrl: './dynamic-accordion-top-header.component.html',
  styleUrls: ['./dynamic-accordion-top-header.component.scss'],
})
export class DynamicAccordionTopHeaderComponent {
  @Input()
  public title: string;

  @Input()
  public action: (...args: any[]) => void;
}
