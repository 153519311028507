import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-reset-filter',
  templateUrl: './reset-filter.component.html',
})
export class ResetFilterComponent {
  @Input() public form: UntypedFormGroup;
  @Input() public field: string;
  @Input() public defaultValue: string;
  @Output() public updateForm: EventEmitter<any> = new EventEmitter();

  public resetFilter(): void {
    const field = this.form.get(this.field) || (this.form.controls[this.field] as UntypedFormControl);

    if (field) {
      field.reset();
    }

    const value = this.defaultValue || field.value;

    this.updateForm.emit({ [this.field]: value });
  }
}
