import { Component } from '@angular/core';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { DestroyBase } from '@core/base/destroy.class';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header-nav-menu',
  templateUrl: 'header-nav-menu.html',
  styleUrls: ['header-nav-menu.scss'],
})
export class HeaderNavMenuComponent extends DestroyBase {
  public items$: Observable<string[]>;

  constructor(private layoutUtilsService: LayoutUtilsService) {
    super();

    this.items$ = this.layoutUtilsService.subMenuTabs$.asObservable();
  }

  public handleTabChange(event: { originalEvent: MouseEvent; index: number }): void {
    this.layoutUtilsService.setNavMenuTabSelected(event.index);
  }
}
