import { Pipe, PipeTransform } from '@angular/core';
import { MapItem } from '@capturum/ui/api';

@Pipe({
  name: 'distinctOptions',
})
export class DistinctOptionsPipe implements PipeTransform {
  public transform(
    value: MapItem[],
    field: string,
    list: any[],
    colIndex: string | number,
    arrayName?: string,
    rowIndex?: string,
  ): MapItem[] {
    if (value && value.length) {
      let excludedItems: string[] = [];
      let currentValue: string;

      if (arrayName) {
        list.forEach((element, index) => {
          if (index === +colIndex) {
            currentValue = element[arrayName][rowIndex][field];
          } else {
            // skip elements form current column
            excludedItems.push(...this.getIds(element[arrayName], field));
          }
        });
      } else {
        excludedItems = this.getIds(list, field);
        currentValue = list[colIndex][field];
      }

      return value.filter((item) => {
        return !excludedItems.includes(item.value) || item.value === currentValue;
      });
    }
  }

  private getIds(list: any[], field: string): string[] {
    return list.reduce((acc, val) => {
      return acc.concat(val[field]);
    }, []);
  }
}
