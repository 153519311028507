<div class="time-picker">
  <div class="time-picker__desktop d-none d-lg-block">
    <cap-input-mask mask="99:99" placeholder="hh:mm" (complete)="validateTime()" [(ngModel)]="value"> </cap-input-mask>
  </div>

  <div (click)="timepicker?.click(); timepicker?.focus()" class="d-lg-none time-picker__mobile">
    <cap-input placeholder="hh:mm" [ngModel]="value" [readonly]="true"></cap-input>
    <input #timepicker class="time-picker__mobile" type="time" [(ngModel)]="value" />
  </div>
</div>
