import { Component } from '@angular/core';
import { ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(TimePickerComponent)],
})
export class TimePickerComponent extends ValueAccessorBase<string> {
  public validateTime(): void {
    const hourAndMin = this.value.split(':');

    if (+hourAndMin[0] >= 25 || +hourAndMin[1] >= 61) {
      this.value = '';
    }
  }
}
