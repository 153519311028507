import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CsvResultError, CsvUploadResponse } from '@core/models/csv-upload-response.model';

@Component({
  selector: 'app-csv-upload-result',
  templateUrl: './csv-upload-result.component.html',
  styleUrls: ['./csv-upload-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsvUploadResultComponent {
  public get result(): CsvUploadResponse {
    return this._result;
  }

  @Input()
  public set result(value: CsvUploadResponse) {
    if (value) {
      this.errors = this.getFormattedErrors(value.errors);
    }

    this._result = value;
  }

  public errors: CsvResultError[];

  private _result: CsvUploadResponse;

  public resultHasErrors(result: CsvUploadResponse): boolean {
    return result && result.errors && typeof result.errors === 'object' && Object.keys(result.errors).length > 0;
  }

  public getResultErrorLength(result: CsvUploadResponse): number {
    return this.resultHasErrors(result) ? Object.keys(result.errors).length : 0;
  }

  public getFormattedErrors(errors: Record<number, string[] | string>): CsvResultError[] {
    let formattedErrors: CsvResultError[] = [];

    if (errors && Object.keys(errors).length) {
      for (const lineNumber in errors) {
        if (errors.hasOwnProperty(lineNumber)) {
          const lineErrors: string[] = !Array.isArray(errors[lineNumber])
            ? [errors[lineNumber] as string]
            : (errors[lineNumber] as string[]);

          formattedErrors = [...formattedErrors, { line: lineNumber, errors: lineErrors }];
        }
      }
    }

    return formattedErrors;
  }

  public trackByLineNumber(index: number, item: CsvResultError): string {
    return item.line;
  }
}
