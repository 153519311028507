import { Component, Input, OnInit } from '@angular/core';
import { InfoTableColumn } from '@shared/modules/info-table/base/info-table-column.model';
import { InfoTableColumnType } from '@shared/modules/info-table/base/info-table-column-type.enum';

@Component({
  selector: 'app-skeleton-table',
  templateUrl: './skeleton-table.component.html',
})
export class SkeletonTableComponent implements OnInit {
  @Input() public rowCount: number;
  @Input() public colCount: number;
  // OR
  @Input() public columns: InfoTableColumn[];
  @Input() public rows: number[];
  @Input() public titles: string[];

  @Input() public showTitles = true;

  public defaultColumns(): InfoTableColumn[] {
    if (this.columns) {
      return this.columns;
    }

    if (!this.titles) {
      this.showTitles = true;
      this.titles = this.defaultTitles();
    }

    return this.titles.map((title) => {
      return {
        field: title,
        title: this.showTitles ? title : '',
        type: InfoTableColumnType.Template,
      };
    });
  }

  public defaultRows(): number[] {
    return Array.from(Array(this.rowCount || 1), (value, index) => {
      return index;
    });
  }

  public defaultTitles(): string[] {
    // Convert a number to a character starting at 'A'
    return Array.from(Array(this.colCount || 1), (value, index) => {
      return String.fromCharCode(index + 65);
    });
  }

  public ngOnInit(): void {
    this.columns = this.columns ? this.columns : this.defaultColumns();
    this.titles = this.titles ? this.titles : this.defaultTitles();
    this.rows = this.defaultRows();
  }
}
