import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: 'secondary-button.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryButtonComponent {
  @Input() public icon: string;
  @Input() public label: string;
  @Input() public disabled: boolean;
  @Input() public styleClass: string;
}
