import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { IIndexableService } from '@core/indexDb/interfaces/indexable-service.model';
import { CompleteConfig, IndexedDbGuard, IndexedDbModel, IndexedDbService, Store } from '@capturum/complete';
import { IndexableServiceHelper } from '@core/indexDb/utils/indexable-service.helper';
import { Injectable, OnDestroy } from '@angular/core';
import { ErrorCodes } from '@core/indexDb/enums/error-codes.enum';
import Dexie from 'dexie';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root',
})
export class IndexableDataService<Entity> extends ApiService<Entity> implements IIndexableService, OnDestroy {
  protected defaultApiOptions: ListOptions;
  protected outdatedEntityKey: string;
  protected destroy$: Subject<boolean> = new Subject();

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
  ) {
    super(api);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getStoreFromService(userId?: string): Store {
    return this.completeConfig.userSpecificDb && !!userId
      ? this.indexedDbService.getStore(userId)
      : this.indexedDbService.getStore();
  }

  public findTable(store: Store): Observable<Dexie.Table<any, any>> {
    return new Observable((observer) => {
      const tableIndex = store.tables.findIndex((table) => {
        return table.name === this.outdatedEntityKey;
      });

      if (tableIndex === -1) {
        observer.error(null);
        observer.complete();
      }

      observer.next(store.table(this.outdatedEntityKey));
      observer.complete();
    });
  }

  public createStore(userId?: string): Observable<Dexie.Table<any, any>> {
    return this.indexedDbGuard.canActivate(null, null).pipe(
      switchMap((canActivate: boolean) => {
        if (!canActivate) {
          throw new Error(ErrorCodes.COULD_NOT_CREATE_STORE);
        }

        const store = this.getStoreFromService(userId);

        if (!store) {
          throw new Error(ErrorCodes.COULD_NOT_CREATE_STORE);
        }

        return this.findTable(store);
      }),
      catchError((err: ErrorCodes) => {
        return throwError(err);
      }),
      takeUntil(this.destroy$),
    );
  }

  public getTable(): Observable<Dexie.Table<any, any>> {
    let userId: string = null;

    if (this.completeConfig.userSpecificDb) {
      const user = this.authService.getUser();

      userId = user ? (user.id as string) : null; // User might not exist, e.g. when logging in/out
    }

    const store = this.getStoreFromService(userId);

    if (!store) {
      return this.createStore(userId).pipe(takeUntil(this.destroy$));
    }

    return this.findTable(store);
  }

  public getData(forceNetworkRequest = false): Observable<any> {
    return this.isDataOutdated(forceNetworkRequest).pipe(
      switchMap((needsUpdate) => {
        return needsUpdate ? this.getRequestedData() : of(null);
      }),
      switchMap(() => {
        return this.getIndexedData();
      }),
    );
  }

  public getRequestedData(): Observable<any> {
    const url = `/${this.endpoint}${this.getOptionsQuery(this.defaultApiOptions)}`;

    return this.apiHttp.get(url).pipe(
      map((response: ApiIndexResult<any>) => {
        return response?.data;
      }),
      switchMap((data: any) => {
        return this.setIndexedData(data);
      }),
      catchError((err) => {
        console.error(err);

        // Try and see if we can return previously loaded indexed data
        return this.getIndexedData();
      }),
    );
  }

  public getIndexedData(): Observable<any> {
    return this.getTable().pipe(
      switchMap((table: Dexie.Table<any, any>) => {
        return table.toArray();
      }),
      switchMap((data) => {
        return this.onIndexedDataFinished(data);
      }),
      catchError((err) => {
        console.error(ErrorCodes.COULD_NOT_RETRIEVE_DATA);

        // Give up
        return this.onFatalError();
      }),
      takeUntil(this.destroy$),
    );
  }

  public setIndexedData(sourceData: any): Observable<any> {
    return this.getTable().pipe(
      switchMap((table: Dexie.Table<any, any>) => {
        return this.onTransformRequestedData(sourceData).pipe(
          switchMap((transformedData: any) => {
            return table.bulkPut(transformedData);
          }),
          switchMap(() => {
            return this.onIndexedDataUpdated(sourceData);
          }),
          tap(() => {
            return IndexableServiceHelper.updateOutdatedDataInStorage(this.outdatedEntityKey, false);
          }),
        );
      }),
      catchError((err) => {
        IndexableServiceHelper.updateOutdatedDataInStorage(this.outdatedEntityKey, true);

        // Give up, but maybe someone else wants to try and catch this error and do something with it
        return throwError(ErrorCodes.COULD_NOT_STORE_DATA);
      }),
      takeUntil(this.destroy$),
    );
  }

  public resolve(): Observable<boolean> {
    return this.getData().pipe(
      map((model: any) => {
        return !!model && !!model.length;
      }),
      takeUntil(this.destroy$),
    );
  }

  public isDataOutdated(forceNetworkRequest = false): Observable<boolean> {
    return this.getTable().pipe(
      switchMap((table: Dexie.Table<any, any>) => {
        return table.count();
      }),
      map((records) => {
        return forceNetworkRequest || records === 0 || IndexableServiceHelper.shouldUpdate(this.outdatedEntityKey);
      }),
      catchError(() => {
        return of(true);
      }), // Something went wrong, assume data is outdated
    );
  }

  public clearData(): Observable<void> {
    return this.getTable().pipe(
      switchMap((table: Dexie.Table<any, any>) => {
        return table ? table.clear() : null;
      }),
      catchError((err) => {
        console.error(ErrorCodes.COULD_NOT_CLEAR_DATA);

        IndexableServiceHelper.updateOutdatedDataInStorage(this.outdatedEntityKey, true);

        return of(null);
      }),
      takeUntil(this.destroy$),
    );
  }

  public onTransformRequestedData(data: any[]): Observable<any> {
    return of(data);
  }

  public onIndexedDataUpdated(data: any): Observable<any> {
    return of(data);
  }

  public onIndexedDataFinished(data: IndexedDbModel[]): Observable<any> {
    return of(data);
  }

  public onFatalError(): Observable<void> {
    return this.clearData().pipe(takeUntil(this.destroy$));
  }
}
