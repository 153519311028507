import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./history-modal.component.scss'],
})
export class HistoryModalComponent {
  @Input() public items: any;
  @Input() public visible: boolean;
  @Input() public planningEntities = false;
  @Output() public modalHide: EventEmitter<void> = new EventEmitter<void>();

  public onHide(): void {
    this.modalHide.emit();
  }
}
