import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-number-keyboard',
  templateUrl: './custom-number-keyboard.component.html',
  styleUrls: ['./custom-number-keyboard.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return CustomNumberKeyboardComponent;
      }),
      multi: true,
    },
  ],
})
export class CustomNumberKeyboardComponent implements ControlValueAccessor, OnInit {
  public inputValue = '';

  @Input() public disabled = true;
  @Input() public startValue = '';

  public onChange = (value: string) => {};

  public onTouch = () => {};

  public ngOnInit(): void {
    this.inputValue = this.startValue.toString();
  }

  public onDeletePress(): void {
    this.inputValue = this.inputValue.substring(0, this.inputValue.length - 1);
    this.onChange(this.inputValue);
  }

  public onBtnPress(value: string): void {
    this.inputValue = this.inputValue + value;
    this.onChange(this.inputValue);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: string): void {
    try {
      this.inputValue = value;
    } catch (e) {
      this.inputValue = '';
    }
  }
}
