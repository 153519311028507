import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent {
  @Input() public headerTemplate: TemplateRef<any>;
  @Input() public bodyTemplate: TemplateRef<any>;
}
