import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss'],
})
export class AccordionListComponent {
  @Output()
  public addClick = new EventEmitter<void>();

  @Input()
  public addPermissions: string[];

  @Input()
  public title: string;
}
