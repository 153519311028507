import { FormAction } from '@core/enums/form-action.enum';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-filter-buttons',
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FilterButtonsComponent {
  public FormAction: typeof FormAction = FormAction;
  @Input() public entity: string;
  @Input() public applyLabel = 'dxp.button.refresh-results';

  @Output() public applyFilter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public resetFilter: EventEmitter<void> = new EventEmitter<void>();
}
