import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { UploaderItem } from '@core/models/uploader-item.model';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {
  @Input() public id: string;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public multiple = true;
  @Input() public isEditMode = false;
  @Input() public control: AbstractControl;
  @Input() public formArray: UntypedFormArray;
  @Input() public formGroupConfig: Record<string, any>;
  @Input() public previewClass = 'col-3';
  @Input() public showThumbnail = true;
  @Input() public wrappingInFile = true;
  @Input() public extensionsAccepted: string[] = [];
  @Input() public previewContent: TemplateRef<string>;
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  public getControlName(): string | null {
    const formGroup = (this.control || this.formArray)?.parent?.controls;
    const controlName =
      Object.keys(formGroup || {}).find((name) => {
        return this.control === formGroup[name];
      }) || null;

    return this.id ? this.id + '-' + controlName : controlName;
  }

  public setFiles(files: File[]): void {
    if (Array.isArray(files) && files.length > 0 && !this.isEditMode) {
      const adjustedFiles: (UploaderItem | File)[] = this.wrappingInFile
        ? files.map((file) => {
            return { file };
          })
        : files;

      this.setFile(adjustedFiles);
    } else if (!this.multiple && this.isEditMode) {
      const file: File = files[0];

      this.setFile(null, file);
    }
  }

  public removeFile(filename: string): void {
    if (this.formArray) {
      const index = this.files.findIndex((item: any) => {
        return item.file ? item.file.name === filename : item.name === filename;
      });

      this.formArray.removeAt(index);
    } else {
      const index = this.files.findIndex((item: any) => {
        return item.file ? item.file.name === filename : item.name === filename;
      });

      this.control.setValue(
        this.files.filter((item, i) => {
          return i !== index;
        }),
      );
    }
  }

  public get fileExtensions(): string {
    return Array.isArray(this.extensionsAccepted) ? this.extensionsAccepted.join(',') : '';
  }

  private setFile(files?: (UploaderItem | File)[], singleFile?: File): void {
    if (this.formArray) {
      if (this.multiple) {
        files.forEach((file) => {
          const formGroup = this.formBuilder.group(this.formGroupConfig);
          const filename = this.wrappingInFile ? (file as { file: File }).file.name : (file as File).name;

          formGroup.patchValue({ ...file, filename });

          this.formArray.push(formGroup);
        });
      } else {
        this.formArray.reset([this.formBuilder.group({ ...singleFile })]);
      }
    } else {
      this.control.setValue(this.multiple ? [...this.files, ...files] : this.isEditMode ? singleFile : files);
      this.control.markAsTouched();
      this.control.markAsDirty();
    }
  }

  private get files(): (UploaderItem | File)[] {
    return this.control?.value || this.formArray?.value || [];
  }
}
