import { Injectable } from '@angular/core';
import { ApiHttpService, ListOptions } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseDataKeyIndexedDbModel,
  BaseDataValueApi,
  BaseTranslation,
  CompleteConfig,
  IndexedDbGuard,
  IndexedDbService,
  User,
} from '@capturum/complete';
import { Observable, of } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { NavigationService } from '@core/services/navigation.service';
import { AuthService } from '@capturum/auth';
import { IndexableDataService } from '@core/indexDb/services/dxp-indexable-data.service';
import { CacheService } from '@core/services/cache.service';

/***
 * We're still going to need this service to supply it to @Emendis/Complete
 */

@Injectable({
  providedIn: 'root',
})
export class DxpIndexableBaseDataKeyService extends IndexableDataService<BaseDataKeyIndexedDbModel> {
  protected endpoint = 'base-data-key';
  protected outdatedEntityKey = 'base_data_keys';
  protected baseDataKeys: BaseDataKeyIndexedDbModel[] = [];
  protected includes = ['attributes', 'values.attributes', 'values', 'values.translations'];
  protected user: User;

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly cacheService: CacheService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly navigationService: NavigationService,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
  ) {
    super(api, translateService, authService, completeConfig, indexedDbService, indexedDbGuard);

    this.defaultApiOptions = {
      perPage: 1000,
    };
  }

  public loadBaseDataKeys(forceReload = false): Observable<any> {
    return this.getData(forceReload);
  }

  public onFatalError(): Observable<void> {
    return super.onFatalError().pipe(
      tap(() => {
        this.cacheService.forceLogout();
      }),
    );
  }

  public getBaseDataKeyValues(key: string): Observable<MapItem[]> {
    const locale = this.user ? this.user?.locale_id : this.authService.getUser()?.locale_id;

    return this.getBaseDataKeyByKey(key).pipe(
      mergeMap((base) => {
        return this.getBaseDataKeyValuesByLocale(base.id, locale);
      }),
    );
  }

  public getBaseDataKeyByKey(key: string): Observable<BaseDataKeyIndexedDbModel> {
    if (this.baseDataKeys.length) {
      return of(
        this.baseDataKeys.find((item) => {
          return item.key === key;
        }),
      );
    } else {
      return this.index().pipe(
        map((response) => {
          return response.data.find((item) => {
            return item.key === key;
          });
        }),
        take(1),
      );
    }
  }

  public getBaseDataKeyValuesByLocale(
    id: string | number,
    localeId?: string | number,
    options?: ListOptions,
    parents = false,
    parentValue?: string,
  ): Observable<MapItem[]> {
    let url = id;

    if (options) {
      url += this.getOptionsQuery(options);
    } else {
      const defaultOptions: ListOptions = { include: this.includes };

      url += this.getOptionsQuery(defaultOptions);
    }

    return this.get<MapItem[]>(url).pipe(
      map((response: any) => {
        return response.values && localeId ? this.getMapItems(localeId, response.values, parents, parentValue) : [];
      }),
    );
  }

  public extractBaseDataKeys(user?: User): void {
    const options: ListOptions = { perPage: 1000 };

    this.index(options).subscribe((response) => {
      this.baseDataKeys = response.data;
    });

    if (user) {
      this.user = user;
    }
  }

  public getTranslation(localeId: string | number, translations: BaseTranslation[]): BaseTranslation {
    return translations.find((item) => {
      return item?.locale_id === localeId;
    });
  }

  public getBaseMapItem(baseItem: BaseDataValueApi, localeId: string | number): MapItem {
    const translate = this.getTranslation(localeId, baseItem?.translations);

    return {
      label: translate?.translation || baseItem.value,
      value: baseItem.id,
      key: baseItem.value,
    };
  }

  public getMapItems(
    localeId: string | number,
    values: BaseDataValueApi[],
    parents: boolean,
    parentValue?: string,
  ): MapItem[] {
    let baseList = values;

    if (parents) {
      baseList = values.filter((item) => {
        return item.parent_id === null;
      });
    } else if (parentValue) {
      baseList = values.filter((item) => {
        return item.parent_id === parentValue;
      });
    }

    return baseList.map((item) => {
      return this.getBaseMapItem(item, localeId);
    });
  }
}
