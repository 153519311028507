import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'squareMetre',
})
export class SquareMetrePipe implements PipeTransform {
  public transform(value: string | number): string {
    return `${value} m<sup>2</sup>`;
  }
}
