import { SyncError } from '@core/models/sync-error.model';

export class SetStoredAmount {
  public static readonly type = '[Sync] Set stored amount items';

  constructor(public amount: number) {}
}

export class SetProgressStatus {
  public static readonly type = '[Sync] Set progress status';

  constructor(public status: boolean) {}
}

export class ResetSync {
  public static readonly type = '[Sync] Reset sync';
}

export class SetSyncErrors {
  public static readonly type = '[Sync] Set sync errors';

  constructor(public syncErrors: SyncError<any>[]) {}
}

export class SetCurrentError {
  public static readonly type = '[Sync] Set current sync error';

  constructor(public syncError: SyncError<any>) {}
}
