import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@core/models/contact.model';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  public transform(contact: Contact, args?: any): string {
    const fieldsNames = ['first_name', 'middle_name', 'last_name'];

    if (contact) {
      return fieldsNames.reduce((acc, item) => {
        return acc + `${contact[item] ? contact[item] : ''} `;
      }, '');
    }

    return null;
  }
}
