import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'isValidFormControl',
})
export class IsValidFormControlPipe implements PipeTransform {
  public transform(value: any, property: string | string[], form?: UntypedFormGroup): boolean {
    if (form) {
      if (Array.isArray(property)) {
        return property.every((item) => {
          return form.controls[item].valid;
        });
      }

      return form.controls[property].valid;
    } else {
      if (Array.isArray(property)) {
        return property.every((item) => {
          return !!value[item];
        });
      }

      return !!value[property];
    }
  }
}
