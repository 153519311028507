import { Pipe, PipeTransform } from '@angular/core';
import { Organisation } from '@core/models/organisation.model';
import { ShipmentInformation } from '@core/models/shipment.model';

@Pipe({
  name: 'getOrganisation',
})
export class GetOrganisationPipe implements PipeTransform {
  public transform(value: ShipmentInformation & { organisation?: Organisation }): Organisation {
    if (value?.client_organisation_id) {
      return value.clientOrganisation;
    }

    if (value?.agent_organisation_id) {
      return value.agentOrganisation;
    }

    if (value?.organisation) {
      return value.organisation;
    }

    return null;
  }
}
