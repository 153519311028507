<div class="flights">
  @if (item?.shipmentInformation?.shippingSchedule?.flights; as flights) {
    @if ('flightStatus' | baseDataOptions | async; as flightStatuses) {
      @if (flights | flight: flightStatuses; as processedFlights) {
        @for (flight of processedFlights; track flight) {
          <div class="flight">
            <div class="flight-info">
              <i [class]="flight.icon" [ngClass]="flight.class"></i>
              <div>
                <p class="flight-number">
                  <span>{{ flight.shipping?.nr }}</span>
                </p>
                <p class="flight-status">{{ flight.label }}</p>
              </div>
              <div class="flight-info-sub-status">
                <app-delayed-shipment-information [flight]="flight"></app-delayed-shipment-information>
              </div>
            </div>
          </div>
        }
      }
    }
  }
</div>
