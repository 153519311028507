import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicAccordionComponent } from '@shared/modules/dynamic-accordion/components/dynamic-accordion/dynamic-accordion.component';
import { DynamicAccordionBodyAddressComponent } from '@shared/modules/dynamic-accordion/components/dynamic-accordion-body-address/dynamic-accordion-body-address.component';
import { DynamicAccordionBodyComponent } from '@shared/modules/dynamic-accordion/components/dynamic-accordion-body/dynamic-accordion-body.component';
import { DynamicAccordionHeaderComponent } from '@shared/modules/dynamic-accordion/components/dynamic-accordion-header/dynamic-accordion-header.component';
import { DynamicAccordionWrapperComponent } from '@shared/modules/dynamic-accordion/components/dynamic-accordion-wrapper/dynamic-accordion-wrapper.component';
import { DynamicAccordionTopHeaderComponent } from '@shared/modules/dynamic-accordion/components/dynamic-accordion-top-header/dynamic-accordion-top-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { CapturumButtonModule } from '@capturum/ui/button';

const components = [
  DynamicAccordionComponent,
  DynamicAccordionHeaderComponent,
  DynamicAccordionBodyComponent,
  DynamicAccordionBodyAddressComponent,
  DynamicAccordionWrapperComponent,
  DynamicAccordionTopHeaderComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, AccordionModule, CapturumButtonModule, TranslateModule],
  exports: components,
})
export class DynamicAccordionModule {}
