import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-forecast-package-type-data',
  template: `
    <div
      [ngClass]="{
        error: item[field]?.value < 0,
        warning: item[field]?.value > 0 && item[field]?.value < item[field]?.signal_value,
      }">
      {{ item[field]?.value }}
    </div>
  `,
})
export class ForecastPackageTypeDataComponent extends ListRendererWidgetBase {}
