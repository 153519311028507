import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MasonryBlockConfig } from '@core/models/masonry-block-config.model';

@Directive({
  selector: '[appMasonryBlock]',
})
export class MasonryBlockDirective {
  @Input('appMasonryBlock')
  public set order(value: number | MasonryBlockConfig) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this._order = value;
  }

  public get order(): number | MasonryBlockConfig {
    return this._order;
  }

  private _order: number | MasonryBlockConfig;

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}
}
