<div class="row mb-4" [class.ellipsis]="ellipsis">
  <div class="col row-label">
    <p>{{ label }}</p>
  </div>

  <div class="col row-value">
    <ng-container [ngTemplateOutlet]="valueTemplate || defaultTemplate"> </ng-container>
  </div>

  <ng-template #defaultTemplate>
    @if (value) {
      <p>{{ value | default: '-' }}</p>
    }

    @if (booleanValue) {
      <p>{{ booleanValue }}</p>
    }
  </ng-template>
</div>
