import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-font-awesome-button',
  templateUrl: './font-awesome-button.component.html',
  styleUrls: ['./font-awesome-button.component.scss'],
})
export class FontAwesomeButtonComponent {
  @Input() public styleClass: string;
  @Input() public faIconClass: string;

  @Output() public buttonClick: EventEmitter<number> = new EventEmitter<number>();
}
