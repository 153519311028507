import { Injectable } from '@angular/core';
import { Observation } from '@core/models/observation.model';
import { from, Observable } from 'rxjs';
import { IndexDbObservation } from '@core/indexDb/model/indexDb-observation.model';
import { ApiFileService } from '@core/api/api-file.service';
import { ApiHttpService } from '@capturum/api';
import { FileService } from '@core/api/file.service';

@Injectable({
  providedIn: 'root',
})
export class ObservationService extends ApiFileService<Observation> {
  protected endpoint = 'observation';

  constructor(
    public apiHttpService: ApiHttpService,
    public fileService: FileService,
  ) {
    super(apiHttpService, fileService);
  }

  public createOffline(data: any): Observable<string> {
    return from(IndexDbObservation.query().add(data));
  }

  public getTotalAmount(): Observable<number> {
    return from(IndexDbObservation.query().count());
  }

  public removeOffline(data: any): Observable<any> {
    if (Array.isArray(data)) {
      return from(IndexDbObservation.query().bulkDelete(data));
    } else {
      return from(IndexDbObservation.query().delete(data));
    }
  }

  public getStoredObservations(): Observable<IndexDbObservation[]> {
    return from(IndexDbObservation.query().toArray());
  }
}
