<app-navigation-button
  [title]="value | defaultDateFormatting: 'dd-MM-yyyy'"
  (navigate)="closeOverlay(); changeDate($event)">
  <div class="overlayTrigger" (click)="overlay.toggle($event); active = true"></div>

  <p-overlayPanel #overlay appendTo="body" [dismissable]="false">
    <ng-template pTemplate>
      <cap-calendar
        styleClass="custom-date-picker"
        [inline]="true"
        [showIcon]="false"
        [(ngModel)]="value"
        (select)="closeOverlay()">
      </cap-calendar>
    </ng-template>
  </p-overlayPanel>

  @if (active) {
    <div class="backDrop" (click)="closeOverlay()"></div>
  }
</app-navigation-button>
