@if (labelTranslationKey) {
  <label class="title">{{ labelTranslationKey | translate }}</label>
}

@if (items) {
  <div class="action-cards">
    @if (items?.length === 0) {
      <div class="action__card action--readonly action--warning">
        <div class="readonly-row d-flex">
          <div class="d-flex flex-column justify-content-center">
            <i class="fas fa-ban"></i>
          </div>
          <div class="content d-flex flex-column justify-content-center">
            {{ noActiveItemsTranslationKey | translate }}
          </div>
        </div>
      </div>
    } @else {
      @for (item of items; track trackByFn($index, item)) {
        <div
          class="action__card action--readonly"
          [attr.id]="'section' + item.value"
          [class.action--disabled]="item.disabled && !isReadOnly"
          [class.action__card--selected]="item.value === value && !item.disabled"
          (click)="selectItem(item)">
          @if (item.disabled || isReadOnly) {
            <div class="readonly-row d-flex">
              <div class="d-flex flex-column justify-content-center">
                <ng-container
                  *ngTemplateOutlet="customLeftIcon || defaultLeftIcon; context: { item: item }"></ng-container>
              </div>
              <div class="content d-flex flex-column justify-content-center">
                {{ item.label }}
                <br />
                @if (disabledItemTranslationKey) {
                  <span class="subtitle mt-1">{{ disabledItemTranslationKey | translate }}</span>
                }
                @if (item.subtitle) {
                  <span class="subtitle mt-1" [innerHTML]="item.subtitle"></span>
                }
              </div>
              @if (showRightIcon) {
                <i class="right-icon {{ rightIcon }}"></i>
              }
            </div>
          } @else {
            <cap-radio [name]="itemGroupName" [value]="item.value" [label]="item.label" [(ngModel)]="value">
            </cap-radio>
            @if (item.subtitle) {
              <span class="custom-subtitle mt-1" [innerHTML]="item.subtitle"></span>
            }
            @if (showRightIcon) {
              <i class="right-icon {{ rightIcon }}"></i>
            }
          }
        </div>
      }
    }
  </div>
}

@if (control) {
  <cap-validator [control]="control"></cap-validator>
}

<ng-template #defaultLeftIcon>
  <i class="left-icon {{ leftIcon }}"></i>
</ng-template>
