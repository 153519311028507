import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ListOptions } from '@capturum/api';
import { MarketDemand } from '@core/models/market-demand.model';
import { Observable } from 'rxjs';
import { MDChart } from '@core/models/market-demand-chart.model';
import { UploadFileService } from './upload-file.service';
import { AsideMenuItem } from '@core/enums/aside-menu-item.model';
import { AppRoutes } from '@core/enums/routes.enum';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root',
})
export class MarketDemandService extends UploadFileService<MarketDemand> {
  public static marketDemandMenu: AsideMenuItem = {
    icon: 'fas fa-chart-line',
    link: `${AppRoutes.admin}/${AppRoutes.dashboard}/${AppRoutes.planning}`,
    label: 'dxp.sidebar.market-demand',
    permission: ['dxp.market-demand.show'],
    key_index: 'market_demand_index',
  };

  protected endpoint = 'market-demand';

  constructor(
    public apiHttp: ApiHttpService,
    public authService: AuthService,
  ) {
    super(apiHttp, authService);
  }

  public chart(data: any, options?: ListOptions): Observable<ApiIndexResult<MDChart>> {
    let url = `/${this.endpoint}/chart`;

    // Prevent empty product_group_id or product_id because the backend will throw an error
    if (data && !data.product_group_id) {
      delete data.product_group_id;
    } else if (data && !data.product_id) {
      delete data.product_id;
    } else if (data && !data.farm_id) {
      delete data.farm_id;
    }

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }
}
