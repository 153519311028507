export enum VisibilityState {
  visible = 'visible',
  hidden = 'hidden',
}

export enum Direction {
  up = 'Up',
  down = 'Down',
}

export enum DataState {
  loading = 'loading',
  resultsUnavailable = 'unavailable',
  resultsEmpty = 'empty',
  completed = 'completed',
  incorrectFilters = 'incorrect_filters',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}
