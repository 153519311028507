<p-table [styleClass]="styleClass" [value]="data">
  <ng-template pTemplate="header">
    <tr id="tableHeader">
      @for (column of columns; track column; let i = $index) {
        <th class="info-table__column {{ column?.titleClass }}">
          @if (column?.title | observablePipe | async; as title) {
            {{ title }}
          } @else {
            @if (column?.headerTemplateRef; as ref) {
              <ng-container *ngTemplateOutlet="ref; context: { index: i, column: column }"></ng-container>
            }
          }
        </th>
      }
    </tr>
  </ng-template>

  <ng-template let-item let-rowIndex="rowIndex" pTemplate="body">
    <tr class="info-table__row {{ item?.name?.tr_style_class }}">
      @for (column of columns; track trackByFn(i, column); let i = $index) {
        @if (!column?.hidden) {
          <td
            class="info-table__column {{ column?.cellClass }}"
            [attr.data-test]="'info-table__column_' + column?.field">
            @switch (column?.type) {
              @case (DataTableType.Boolean) {
                @if ((config$ | async)?.texts; as texts) {
                  {{ (item | propertyValue: column?.field) ? texts.positive : texts.negative }}
                }
              }
              @case (DataTableType.Template) {
                @if (templates | columnTemplate: column?.field; as customTemplate) {
                  <ng-container
                    [ngTemplateOutletContext]="{
                      item: item,
                      index: rowIndex,
                      rowIndex: rowIndex,
                      columnIndex: i,
                      column: column,
                    }"
                    [ngTemplateOutlet]="customTemplate">
                  </ng-container>
                }
              }
              @default {
                {{ item | propertyValue: column?.field }}
              }
            }
          </td>
        }
      }
    </tr>
  </ng-template>

  <ng-template *ngIf="footer" let-item let-rowIndex="rowIndex" pTemplate="footer">
    <tr>
      @for (column of footer; track column; let i = $index) {
        <td class="info-table__column-footer {{ column?.titleClass }}">
          @switch (column?.type) {
            @case (DataTableType.Template) {
              @if (templates | columnTemplate: column?.field; as customTemplate) {
                <ng-container
                  [ngTemplateOutletContext]="{ item: item, index: rowIndex, column: column }"
                  [ngTemplateOutlet]="customTemplate">
                </ng-container>
              }
            }
          }
        </td>
      }
    </tr>
  </ng-template>
</p-table>
