import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  @Input() public styleClass = 'col-3';
  @Input() public items: any[];
  @Input() public deletable = false;
  @Input() public nameProperty = 'name';
  @Input() public imagePathProperty: string;
  @Input() public showThumbnail = true;
  @Output() public removeClick: EventEmitter<string> = new EventEmitter();
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();

  public handleRemoveIconClick(event: Event, filename: string): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.removeClick.next(filename);
  }
}
