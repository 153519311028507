<div class="hz-line mt-3">
  <span class="hz-line__left"></span>

  <span class="hz-line__title-wrapper">
    <span class="hz-line__badge badge primary rounded-circle">
      {{ badge }}
    </span>

    <span class="hz-line__title">
      {{ title }}
    </span>
  </span>

  <span class="hz-line__right"></span>
</div>
