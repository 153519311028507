@if (items && items?.length) {
  <div class="row mt-3">
    <div class="col-12">
      <div class="ibox">
        @if (title) {
          <div class="ibox-title">
            <h5>
              {{ title | translate }}
            </h5>
          </div>
        }
        <div class="ibox-content">
          <div class="row">
            @for (item of items; track item) {
              <ng-template [ngxPermissionsOnly]="item.permission">
                <div class="col-12 col-sm-6 col-lg-3 col-xl-2">
                  <div
                    [routerLink]="item.routerLink"
                    [attr.data-test]="item.routerLink"
                    class="config-card"
                    (click)="onClick(item)">
                    <i [class]="item.iconClass"></i>
                    <div class="ibox-title">
                      {{ item.titleTranslationKey | translate }}
                    </div>
                  </div>
                </div>
              </ng-template>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
