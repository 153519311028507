import { Pipe, PipeTransform } from '@angular/core';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { DatePipe } from '@angular/common';
import { ToDatePipe } from '@shared/pipes/to-date.pipe';
import { SafariHelper } from '@core/utils/safari.helper';

/*
 * Chain DefaultPipe, DatePipe, ToDatePipe together. This will avoid the necessity to chain them manually in the view.
 * */
@Pipe({
  name: 'defaultDateFormatting',
})
export class DefaultDateFormattingPipe implements PipeTransform {
  private defaultPipe = new DefaultPipe();
  private datePipe = new DatePipe('en-US');
  private toDatePipe = new ToDatePipe();

  /**.
   * @param {string} value - The value that pipe receives.
   * @param {string} dateFormatting - Date format that will be applied.
   * @param {string} defaultValue - The returned value of the pipe in case when datePipe returns null.
   */
  public transform(value: any, dateFormatting = 'dd-MM-yyyy @ HH:mm', defaultValue = '-'): string {
    let newValue = value;

    newValue = SafariHelper.checkIsSafariBrowser(newValue);

    if (!newValue || !new Date(newValue).getTime()) {
      return newValue;
    }

    return this.defaultPipe.transform(
      this.datePipe.transform(this.toDatePipe.transform(newValue), dateFormatting),
      defaultValue,
    );
  }
}
