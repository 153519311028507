<div class="d-flex justify-content-between">
  @if (leftTitle) {
    <h2 class="full-history">
      {{ leftTitle | translate }}
    </h2>
  }

  @if (!showFullHistory) {
    <div class="show-full-history" (click)="openDialog()">
      {{ 'dxp.history.show-full-history' | translate }}
    </div>
  }
</div>

<div class="history">
  <div class="timeline">
    @for (historyItem of historyItems | keyvalue: originalOrder; track historyItem; let i = $index) {
      @if ((showFullHistory || (!showFullHistory && i < 3)) && historyItem.value?.modelLogs; as logs) {
        <div class="timeline__card">
          @for (item of logs; track item) {
            <div class="timeline__content">
              <div class="timeline__scheduled semi-bold pb-1 ut-primary-text">
                {{ item.created_at | defaultDateFormatting: 'dd-MM-yyyy' }} -
                <ng-container *ngTemplateOutlet="headerTemplate || defaultHeader; context: { $implicit: item }">
                </ng-container>
                <ng-template #defaultHeader>
                  @if (entityTranslationKey) {
                    {{ entityTranslationKey | translate }}
                  } @else {
                    {{
                      'dxp.model-log.entity.' + item.entity_key + (planningEntities ? '-planning' : '')
                        | lowercase
                        | translate
                    }}
                  }
                </ng-template>
                {{ 'dxp.model-log.action.' + item.action | lowercase | translate }}
              </div>
              <div class="timeline__created">
                {{
                  'dxp.history.created-by'
                    | translate
                      : {
                          user: item.user?.name,
                          date: item.created_on | defaultDateFormatting,
                        }
                }}
              </div>
              <div class="timeline__body mt-2">
                @if ((item.diffs | keyvalue).length) {
                  <div class="body">
                    <hr />
                    @for (diff of item.diffs | keyvalue; track diff) {
                      <div class="row">
                        @if (
                          diff.value.hideNew === undefined ||
                          !(diff.value.hideNew === true) ||
                          diff.value.hideOld === undefined ||
                          !(diff.value.hideOld === true)
                        ) {
                          <div class="col-md-6 semi-bold dont-break-out">
                            {{ 'dxp.' + item.entity_key + '.' + diff.key + '.label' | lowercase | translate }}
                          </div>
                          <div class="col-md-6 dont-break-out">
                            @if (diff.value.hideNew === undefined || !(diff.value.hideNew === true)) {
                              @if (diff.value.type === 'date') {
                                <span
                                  >{{
                                    (diff.value.new | defaultDateFormatting: 'dd-MM-yyyy HH:mm') ||
                                      ('dxp.model-log.empty' | translate)
                                  }}
                                </span>
                              }
                              @if (!diff.value.type || diff.value.type !== 'date') {
                                <span>{{ diff.value.new || ('dxp.model-log.empty' | translate) }}</span>
                              }
                            }
                            @if (diff.value.hideOld === undefined || !(diff.value.hideOld === true)) {
                              <br />
                              @if (diff.value.type === 'date') {
                                <span class="strikethrough">{{
                                  (diff.value.old | defaultDateFormatting: 'dd-MM-yyyy HH:mm') ||
                                    ('dxp.model-log.empty' | translate)
                                }}</span>
                              }
                              @if (!diff.value.type || diff.value.type !== 'date') {
                                <span class="strikethrough">{{
                                  diff.value.old || ('dxp.model-log.empty' | translate)
                                }}</span>
                              }
                            }
                          </div>
                        }
                      </div>
                    }
                    @if (item.comment || item.reason) {
                      <hr />
                      <div class="row">
                        <div class="col-md-6 semi-bold dont-break-out">
                          {{ 'dxp.history.comment' | translate }}
                        </div>
                        <div class="col-md-6 dont-break-out">
                          {{ item.comment ? item.comment : item.reason }}
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
    }

    @if (!config && isEmptyHistoryItems) {
      <div class="timeline__card">
        <div class="timeline__content no-results py-3">
          <p>
            {{ 'dxp.history.no-history-log' | translate }}
          </p>
        </div>
      </div>
    }
  </div>
</div>
