<main class="body">
  @for (data of content; track data) {
    <div class="row mb-3">
      <div class="col semi-bold">
        <h4 class="label">{{ data.label }}</h4>
      </div>
      <div class="col">
        <div class="value">{{ data.value }}</div>
      </div>
    </div>
  }
</main>
