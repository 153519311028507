import { Injectable } from '@angular/core';
import { ApiIndexResult, ApiListResult, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { UserService as CompleteUserService } from '@capturum/complete';
import { MapItem } from '@capturum/ui/api';
import { ConverterUtil } from '@core/utils/converter-util';
import { Farm } from '@core/models/farm.model';
import { DateHelper } from '@core/utils/date.helper';
import { map } from 'rxjs/operators';
import User from '@core/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CompleteUserService {
  public farms(options?: ListOptions): Observable<ApiIndexResult<Farm>> {
    let url = `/${this.endpoint}/farms/list`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiIndexResult<Farm>>(url);
  }

  public getAllFarmsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    let url = `/farm/list/all`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url).pipe(
      map((response: ApiListResult) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public getActiveFarmByKeyOfFarmRoleUsers(keyOfFarmRole: string, options?: ListOptions): Observable<MapItem[]> {
    let url = `/active-farm/users/${keyOfFarmRole}`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url).pipe(
      map((response: ApiListResult) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public getPlannedUsers(id: string, options?: ListOptions): Observable<MapItem[]> {
    const today = DateHelper.getToday();

    let url = `/${this.endpoint}/planned/list/${today}/${id}/last`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url).pipe(
      map((response: ApiListResult) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public getPlannedUsersPerDate(date: string): Observable<ApiListResult> {
    const url = `/${this.endpoint}/planned/list/${date}`;

    return this.apiHttp.get<ApiListResult>(url);
  }

  public getItemsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    return this.list(options).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public setPassword(userId: string, data: { password: string }, options?: ListOptions): Observable<User> {
    let url = `/${this.endpoint}/${userId}/password`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.patch(url, data);
  }
}
