import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'mapItemsByKey',
})
export class MapItemsByKeyPipe implements PipeTransform {
  public transform(values: any[], key: string): any[] {
    return (
      values &&
      values.map((mapItem) => {
        return get(mapItem, key);
      })
    );
  }
}
