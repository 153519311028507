import { Component, Input } from '@angular/core';
import { ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'app-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(AmountInputComponent)],
})
export class AmountInputComponent extends ValueAccessorBase<number> {
  @Input() public label: string;

  public updateValue(nr: number): void {
    this.value = Number(this.value) + nr;
  }
}
