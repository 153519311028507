import { Pipe, PipeTransform } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { UntypedFormControl } from '@angular/forms';

@Pipe({
  name: 'canDeleteFarm',
})
export class CanDeleteFarmPipe implements PipeTransform {
  public transform(farms: MapItem[], formGroup: any): boolean {
    const farmId = (formGroup.controls.farm_id as UntypedFormControl).value;

    return farms.some((farm) => {
      return farm?.value === farmId;
    });
  }
}
