import { Pipe, PipeTransform } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { ActionsKeyEnum } from '@core/enums/actions-key.enum';

@Pipe({
  name: 'generateActions',
})
export class GenerateActionsPipe implements PipeTransform {
  public transform(documentItem: any, actions: any): TableAction[] {
    return documentItem.email
      ? actions.filter((item) => {
          return item.key === ActionsKeyEnum.resendDocument;
        })
      : actions.filter((item) => {
          return item.key !== ActionsKeyEnum.resendDocument;
        });
  }
}
