import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique',
})
export class UniquePipe implements PipeTransform {
  public transform(value: any[], selectedValues: any[], property: string, excludedValues?: any[]): any[] {
    if (!value) {
      return value;
    }

    let excludedItems = [];

    if (excludedValues) {
      excludedItems = value.filter((item) => {
        return excludedValues.includes(item[property]);
      });
    }

    return [
      ...excludedItems,
      ...value.filter((item) => {
        return !selectedValues.includes(item[property]);
      }),
    ];
  }
}
