<div class="card my-3">
  <div class="card-header bg-white">
    <div class="title d-inline-block">
      <span class="semi-bold"
        ><i class="fas fa-file-csv"></i>&nbsp; <i>{{ result.filename }}</i></span
      >
    </div>
  </div>

  <div id="card-body" class="card-body">
    <div class="row py-2">
      <div class="col semi-bold">{{ 'dxp.market-demand.upload.created.label' | translate }}</div>

      <div class="col text-right">{{ result.created }}</div>
    </div>

    <div class="row py-2">
      <div class="col semi-bold">{{ 'dxp.market-demand.upload.updated.label' | translate }}</div>

      <div class="col text-right">{{ result.updated }}</div>
    </div>

    <div class="row py-2">
      <div class="col semi-bold">{{ 'dxp.market-demand.upload.total_errors.label' | translate }}</div>

      <div class="col text-right">{{ errors.length }}</div>
    </div>

    <div class="error-list mt-3">
      @for (line of errors; track trackByLineNumber($index, line)) {
        <div class="error-item">
          <div class="d-flex">
            <div class="error-item-icon">
              <i class="fas fa-exclamation-circle"></i>
            </div>
            <span class="error-item-title"> {{ 'dxp.price-rate.line' | translate }} {{ line.line }} </span>
          </div>
          <ul class="error-item-errors">
            @for (error of line.errors; track error) {
              <li>
                {{ error }}
              </li>
            }
          </ul>
        </div>
      }
    </div>
  </div>
</div>
