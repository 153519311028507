import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-card-body-row',
  templateUrl: './card-body-row.component.html',
  styleUrls: ['./card-body-row.component.scss'],
})
export class CardBodyRowComponent {
  @Input() public label: string;
  @Input() public value: string | number;
  @Input() public booleanValue = false;
  @Input() public valueTemplate: TemplateRef<any>;
  @Input() public ellipsis = false;
}
