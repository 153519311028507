export const API_DATE_PROPERTIES = [
  'starts_at',
  'ends_at',
  'created_at',
  'updated_at',
  'deleted_at',
  'checked_in_at',
  'sorted_at',
  'cut_date_at',
  'harvest_date',
  'planted_date',
  'plowed_at',
  'observed_at',
  'next_harvest_planning_at',
  'closed_at',
  'first_delivery_at',
  'first_collection',
  'last_collection',
  '_historical_update_at',
  'next_historical_update_at',
  'delivery_date',
  'last_login_at',
  'last_log',
  'first_harvest_at',
  'last_harvest_at',
  'departure_time_at',
  'arrival_time_warehouse_at',
  'date_end',
  'date_start',
  'previous_login_at',
  'boxed_at',
  'rejected_at',
  'shipping_date',
  'delivery_at',
  'supply_date',
  'production_date',
  'start_time',
  'break_1_starts_at',
  'break_2_starts_at',
  'break_3_starts_at',
  'break_4_starts_at',
  'received_at',
];

export const API_DATE_BLACKLIST = [
  '/api/role/translation',
  '/api/role/module',
  '/api/base-data-key',
  '/api/role/setting',
  '/api/role/base-data',
  '/api/role/permission',
  '/api/info/farm-permission',
];

export enum DatesFormats {
  displayFormat = 'YYYY-MM-DD HH:mm:ss',
  sendFormat = 'YYYY-MM-DDTHH:mm:ssZZ',
  dayFormat = 'YYYY-MM-DD',
  dayMonthYearTime = 'DD-MM-YYYY HH:mm',
  monthDayYearFormat = 'MMM Do YYYY',
  displayFormatDateFns = 'yyyy-MM-dd HH:mm:ss',
  sendFormatDateFns = "yyyy-MM-dd'T'HH:mm:ssxx",
}
