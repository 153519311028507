import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { Farm } from '@core/models/farm.model';
import { MapItem } from '@capturum/ui/api';
import { ConverterUtil } from '@core/utils/converter-util';
import { NgxPermissionsService } from 'ngx-permissions';
import { SortDirection } from '@core/enums/ui-general.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FarmService extends ApiService<Farm> {
  protected endpoint = 'farm';

  constructor(
    public apiHttpService: ApiHttpService,
    private ngxPermissionService: NgxPermissionsService,
  ) {
    super(apiHttpService);
  }

  public getItemsAsListOptions(permission?: string, options?: ListOptions): Observable<MapItem[]> {
    const defaultSortOptions = [
      {
        field: 'name',
        direction: SortDirection.asc,
      },
    ];
    let listOptions: ListOptions;

    if (options) {
      // Set default sorting if sort option is not set
      listOptions = { ...options, sort: options.sort || defaultSortOptions };
    } else {
      listOptions = {
        sort: defaultSortOptions,
      };
    }

    let result = this.list(listOptions).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );

    if (permission) {
      result = result.pipe(
        map((farmOptions) => {
          if (this.ngxPermissionService.getPermission('dxp.farm.manage')) {
            return farmOptions;
          }

          return farmOptions.filter((farmOption) => {
            return this.ngxPermissionService.getPermission(`${permission}.${farmOption.value}`);
          });
        }),
      );
    }

    return result;
  }

  public switchActiveFarm(data: { farm_id: string }, options?: ListOptions): Observable<ApiIndexResult<string>> {
    let url = `/${this.endpoint}/switch`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public getAllFarms(): Observable<MapItem[]> {
    return this.apiHttp.get<ApiSingleResult<MapItem[]>>(`/${this.endpoint}/list/all`).pipe(
      map((response) => {
        return ConverterUtil.customObjectToOption(response?.data, ['label'], 'key');
      }),
    );
  }

  public getFarmsByProductIdCountryId(productId: string, countryId?: string): Observable<ApiIndexResult<Farm>> {
    let url = `/${this.endpoint}/active-production/${productId}`;

    if (countryId) {
      url += `/${countryId}`;
    }

    return this.apiHttp.get<ApiIndexResult<Farm>>(url);
  }

  public getActiveCountries(): Observable<MapItem[]> {
    return this.apiHttp.get<ApiSingleResult<MapItem[]>>(`/${this.endpoint}/active-countries/list`).pipe(
      map((response) => {
        return ConverterUtil.customObjectToOption(response?.data, ['label'], 'key');
      }),
    );
  }
}
