<div class="switch-farms">
  <div class="search-wrapper">
    <div class="search">
      <i class="far fa-search"></i>
      <cap-input
        styleClass="search cap-rounded"
        [formControl]="searchControl"
        [placeholder]="'dxp.farm-switch.search.placeholder' | translate">
      </cap-input>
    </div>
  </div>

  @if (farms$ | async; as farms) {
    @if (farms?.length) {
      <ul class="farms">
        @for (farm of farms; track farm) {
          <li (click)="changeFarm(farm)" [ngClass]="{ active: farm?.value === (activeFarm$ | async)?.id }">
            {{ farm?.label }}
          </li>
        }
      </ul>
    } @else {
      <div class="no-results">
        <i class="far fa-search"></i>
        <p class="value">"{{ searchControl?.value }}"</p>
        <p class="not-found">{{ 'dxp.farm-switch.no-result.not-found' | translate }}</p>
        <p class="note">{{ 'dxp.farm-switch.no-result.description' | translate }}</p>
      </div>
    }
  } @else {
    <ul class="farms">
      @for (i of [1, 2, 3]; track i) {
        <li>
          <cap-skeleton></cap-skeleton>
        </li>
      }
    </ul>
  }
</div>
