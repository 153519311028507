<div class="form-group countable-input mb-0">
  @if (label) {
    <label>{{ label }}</label>
  }

  <div class="d-flex input-number-increment">
    <cap-button
      icon="fas fa-minus"
      styleClass="icon minus secondary"
      [attr.data-test]="'minus-button'"
      [disabled]="value === 0"
      (onClick)="updateValue(-1)">
    </cap-button>

    <cap-input filter="pint" type="number" [(ngModel)]="value"></cap-input>

    <cap-button icon="fas fa-plus" styleClass="icon plus secondary" (onClick)="updateValue(1)"> </cap-button>
  </div>
</div>
