import { IndexedDbModel } from '@capturum/complete';

export class IndexDbHarvest extends IndexedDbModel {
  // Configuration
  public entity = 'harvest';
  public table = 'harvests';
  public schema = 'id';
  public sync = true;

  // Fields
  public package_id: string;
  public block_id: string;
  public user_id: string;
  public cut_date_at: string;
  public amount: number;

  // Constructor
  constructor(attributes: any) {
    super(attributes);
  }
}
