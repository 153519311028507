@if (items) {
  <p-dialog
    (onHide)="onHide()"
    [(visible)]="visible"
    [blockScroll]="true"
    [closable]="true"
    [closeOnEscape]="true"
    [draggable]="false"
    [header]="'dxp.general.model-log.title' | translate"
    [modal]="true"
    styleClass="responsive-dialog">
    <app-timeline-model-log
      [items]="items"
      [planningEntities]="planningEntities"
      [isCollapsable]="false"
      [showFullHistory]="true">
    </app-timeline-model-log>
  </p-dialog>
}
