import { Component, Input } from '@angular/core';
import { Flight } from '@core/enums/shipping-movements.model';

@Component({
  selector: 'app-delayed-shipment-information',
  templateUrl: './delayed-shipment-information.component.html',
})
export class DelayedShipmentInformationComponent {
  @Input() public flight: Flight;
}
