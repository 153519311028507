import { Pipe, PipeTransform } from '@angular/core';

export interface ComparisonItem {
  key: string;
  value: any;
}

@Pipe({
  name: 'contains',
})
export class ContainsPipe implements PipeTransform {
  public transform(input: any, comparisons: ComparisonItem[]): any {
    if (!Array.isArray(input)) {
      return input;
    }

    return input
      .map((obj) => {
        return comparisons
          .map((compare) => {
            return obj[compare.key] === compare.value;
          })
          .some((value) => {
            return !!value;
          });
      })
      .some((value) => {
        return !!value;
      });
  }
}
