import { BuildersListKey } from '@core/enums/builders-key.enum';
import { Component, Input, ViewChild } from '@angular/core';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-sales-orders',
  templateUrl: './sales-orders.component.html',
})
export class SalesOrdersComponent {
  @Input() public id: string;
  @Input() public buildersKey: BuildersListKey;

  @ViewChild(CapturumListRendererComponent) public listRenderer: CapturumListRendererComponent;
}
