import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { HttpHeaders } from '@angular/common/http';
import { CsvUploadResponse } from '../models/csv-upload-response.model';
import { Observable } from 'rxjs';
import { AuthService } from '@capturum/auth';
import { IUploadBase } from '@core/models/upload-base.model';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService<Model> extends ApiService<Model> implements IUploadBase {
  constructor(
    public apiHttp: ApiHttpService,
    public authService: AuthService,
  ) {
    super(apiHttp);
  }

  public uploadCsvFiles(file: File | File[], type?: string): Observable<CsvUploadResponse> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.append('Authorization', 'Bearer ' + this.authService.getToken());
    headers.delete('Content-Length');

    const data: FormData = this.filesToFormData(file, type);

    return this.apiHttp.post(this.uploadFilesUrl(), data, { headers });
  }

  public filesToFormData(file: File | File[], type?: string): FormData {
    const data: FormData = new FormData();

    if (Array.isArray(file)) {
      file.forEach((item) => {
        data.append('files', item);
      });
    } else if (file) {
      data.append('file', file);
    }

    if (type) {
      data.append('type', type);
    }

    return data;
  }

  public uploadFilesUrl(): string {
    return `/${this.endpoint}/upload`;
  }
}
