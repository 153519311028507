import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScreenType } from '@core/enums/screen-type.enum';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  public screenTypeChanged$: BehaviorSubject<ScreenType> = new BehaviorSubject<ScreenType>(ScreenType.XS);
  private activeScreenType: ScreenType = null;

  public constructor() {
    // Determine initial ScreenType based on width of screen
    this.resizeListener();

    // Keep listening for updates to screen width
    window.addEventListener('resize', this.resizeListener); // TODO: Run outside of zone.js
  }

  public static getWindowWidth(): number {
    return Math.max(
      document?.body?.scrollWidth,
      document?.documentElement?.scrollWidth,
      document?.body?.offsetWidth,
      document?.documentElement?.offsetWidth,
      document?.documentElement?.clientWidth,
    );
  }

  private resizeListener: any = () => {
    const width = ResponsiveService.getWindowWidth();
    const newType =
      width < 576
        ? ScreenType.XS
        : width < 768
          ? ScreenType.SM
          : width < 992
            ? ScreenType.MD
            : width < 1200
              ? ScreenType.LG
              : width < 1367
                ? ScreenType.XL
                : ScreenType.XXL;

    if (this.activeScreenType !== newType) {
      this.activeScreenType = newType;
      this.screenTypeChanged$.next(this.activeScreenType);
    }
  };
}
