<div [class]="props.styleClass || ''">
  @if (props.subTitle) {
    <h3 class="pb-3">{{ props.subTitle }}</h3>
  }

  @for (item of field.fieldGroup; track item; let i = $index) {
    <div class="row">
      <formly-field [field]="item" [class]="'col'"></formly-field>
      <div class="col-1 d-flex align-items-center">
        @if (field?.props?.instantDelete || (field?.props?.items | canDeleteFarm: formControl?.at(i))) {
          <i (click)="remove(i)" class="fas fa-trash clickable text-danger"></i>
        }
      </div>
    </div>
  }

  <div class="mr-5">
    <cap-button
      type="button"
      icon="fas fa-plus"
      styleClass="secondary float-left semi-bold"
      [label]="props.addText"
      (onClick)="add(); click($event)">
    </cap-button>
  </div>
</div>
