import { Component, Input } from '@angular/core';
import { DynamicAccordionItem } from '@shared/modules/dynamic-accordion/models/dynamic-accordion-item';

/**
 * Dynamic accordion with default templates
 *
 * @example
 * <app-dynamic-accordion-wrapper [title]="'Title'" [items]="items"></app-dynamic-accordion-wrapper>
 *
 * Dynamic accordion with predefined accordionBodyTemplate
 * @example
 * <app-dynamic-accordion-wrapper [title]="'Address'" [items]="items" [accordionBodyTemplate]="'Address'">
 * </app-dynamic-accordion-wrapper>
 *
 * Dynamic accordion with completely custom templates (Works only when isCustomTable input property is true)
 * @example
 * <app-dynamic-accordion-wrapper [isCustomTemplate]="true">
 *   <div class="header-template">
 *     <h1>My projected header</h1>
 *   </div>
 
 *   <div class="accordion-template">
 *     <app-dynamic-accordion *ngFor="let item of items | async">
 *       <div class="accordion-header-template">My projected accordion-header</div>
 *       <div class="accordion-body-template">My projected accordion-body</div>
 *     </app-dynamic-accordion>
 *   </div>
 * </app-dynamic-accordion-wrapper>
 */
@Component({
  selector: 'app-dynamic-accordion-wrapper',
  templateUrl: './dynamic-accordion-wrapper.component.html',
  styleUrls: ['./dynamic-accordion-wrapper.component.scss'],
})
export class DynamicAccordionWrapperComponent {
  @Input()
  public items: DynamicAccordionItem[] = [];

  @Input()
  public title: string;

  @Input()
  public subtitle?: string;

  @Input()
  public action?: (...args: any[]) => void;

  @Input()
  public headerTemplate = 'Default';

  @Input()
  public accordionHeaderTemplate = 'Default';

  @Input()
  public accordionBodyTemplate: 'Default' | 'Address' = 'Default';

  @Input()
  public isCustomTemplate = false;
}
