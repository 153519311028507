import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-formly-repeat-type',
  templateUrl: './formly-repeat-type.component.html',
})
export class FormlyRepeatTypeComponent extends FieldArrayType {
  public remove(i: number): void {
    this.props.onDelete(this.formControl.at(i) as UntypedFormGroup);
    super.remove(i);
  }

  public click($event: any): void {
    this.props.onAddClick($event);
  }
}
