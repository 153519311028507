import { Pipe, PipeTransform } from '@angular/core';
import { Flight, ShippingMovements } from '@core/enums/shipping-movements.model';
import { FlightStatus } from '@core/enums/flight-status.enum';
import { MapItem } from '@capturum/ui/api';

@Pipe({
  name: 'flight',
})
export class FlightPipe implements PipeTransform {
  public transform(shippingMovements: ShippingMovements[], flightStatuses: MapItem[]): (MapItem & Flight)[] {
    return shippingMovements.map((shipping) => {
      let currentStatus = flightStatuses.find((status) => {
        return status.value === shipping.flight_status_base_data_value_id;
      }) as unknown as MapItem & { icon: string };

      switch (currentStatus?.key) {
        case FlightStatus.scheduled:
          currentStatus = { ...currentStatus, icon: 'fas fa-plane fa-rotate-270' };
          break;
        case FlightStatus.landed:
          currentStatus = { ...currentStatus, icon: 'fas fa-plane-arrival' };
          break;
        case FlightStatus.departed:
          currentStatus = { ...currentStatus, icon: 'fas fa-plane-departure' };
          break;
        default:
          break;
      }

      return {
        ...currentStatus,
        shipping,
        class: shipping?.arrival_delay ? 'red' : 'green',
      };
    });
  }
}
