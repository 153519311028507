import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavigateDirection } from '@shared/models/navigation-direction.model';
import { DateHelper } from '@core/utils/date.helper';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'app-date-picker-button',
  templateUrl: './date-picker-button.component.html',
  styleUrls: ['./date-picker-button.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(DatePickerButtonComponent)],
})
export class DatePickerButtonComponent extends ValueAccessorBase<Date> {
  @Output() public dateChange: EventEmitter<Date> = new EventEmitter();

  @Input()
  public set value(value: Date) {
    if (this.innerValue !== value) {
      this.innerValue = value;
    }

    const transformedValue = this.getValueTransform ? this.getValueTransform(value) : value;

    this.changed.forEach((f) => {
      return f(transformedValue);
    }); // Update subscriptions
    this.dateChange.emit(transformedValue); // Update listeners
  }

  public get value(): Date {
    return this.innerValue;
  }

  @ViewChild('overlay') public overlayPanelRef: OverlayPanel;

  public active: boolean;

  public changeDate(action: NavigateDirection): void {
    this.value = new Date(DateHelper.offsetDay(this.innerValue, action === NavigateDirection.next ? 1 : -1));
  }

  public closeOverlay(): void {
    this.overlayPanelRef.hide();
    this.active = false;
  }
}
