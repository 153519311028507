import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceCommaSeparator',
})
export class ReplaceCommaSeparatorPipe implements PipeTransform {
  public transform(characteristics: string[]): string {
    if (characteristics && characteristics[0].trim() === '') {
      if (
        !characteristics.every((value) => {
          return value.trim() === '';
        })
      ) {
        characteristics?.shift();
      }

      return '-' + characteristics.join('-');
    }

    return characteristics?.join('-');
  }
}
