import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigateDirection } from '@shared/models/navigation-direction.model';

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationButtonComponent {
  public navigateDirection: typeof NavigateDirection = NavigateDirection;

  @Input() public title: string;
  @Output() public navigate: EventEmitter<NavigateDirection> = new EventEmitter();
}
