import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { FileService } from '@core/api/file.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiFileService<Model> extends ApiService<Model> {
  protected filePropertyName = 'files';

  constructor(
    public apiHttpService: ApiHttpService,
    public fileService: FileService,
  ) {
    super(apiHttpService);
  }

  public getWithFile(id: string, options?: ListOptions): Observable<any> {
    return this.get(id, options).pipe(
      map(async (result) => {
        if (result[this.filePropertyName]) {
          for (const file of result[this.filePropertyName]) {
            const fileData = await this.fileService.get(file.id).toPromise();

            file.data = `data:image/jpeg;base64,${fileData ? fileData.file : null}`;
          }
        }

        return result;
      }),
    );
  }
}
