<header class="dynamic-accordion__header">
  <ng-container class="header">
    @switch (headerTemplate) {
      <!--HEADER-->
      @case ('Default') {
        <app-dynamic-accordion-top-header [title]="title" [action]="action"></app-dynamic-accordion-top-header>
      }
      <!-- PROJECTED HEADER -->
      @case ('Custom') {
        <ng-content select=".header-template"></ng-content>
      }
    }
  </ng-container>
</header>

<main class="dynamic-accordion__main">
  <!--ACCORDION PROJECTED CONTENT-->
  @if (isCustomTemplate) {
    <ng-content select=".accordion-template"></ng-content>
  }

  @if (!isCustomTemplate) {
    @if (items && items.length) {
      @for (item of items; track item) {
        <app-dynamic-accordion>
          <!--ACCORDION HEADER-->
          <ng-container class="accordion-header">
            @switch (accordionHeaderTemplate) {
              @case ('Default') {
                <app-dynamic-accordion-header [content]="item.header"></app-dynamic-accordion-header>
              }
            }
          </ng-container>
          <!--ACCORDION BODY-->
          <ng-container class="accordion-body">
            @switch (accordionBodyTemplate) {
              @case ('Default') {
                <app-dynamic-accordion-body [content]="item.body"></app-dynamic-accordion-body>
              }
              @case ('Address') {
                <app-dynamic-accordion-body-address [content]="item.body"></app-dynamic-accordion-body-address>
              }
            }
          </ng-container>
        </app-dynamic-accordion>
      }
    } @else {
      {{ 'table.no_results' | translate }}
    }
  }
</main>
