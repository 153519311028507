export class IndexableServiceHelper {
  public static shouldUpdate(updatedKey: string): boolean {
    const storeValue = IndexableServiceHelper.getStoredValueAsObject('updated_key_data');

    return storeValue.hasOwnProperty(updatedKey) ? storeValue[updatedKey] : true;
  }

  public static getStoredValueAsObject(storageKey: string): any {
    const storedValue = localStorage.getItem(storageKey);

    return storedValue ? JSON.parse(storedValue) : {};
  }

  public static updateStoredValueInObject(storageKey: string, updateKey: string, newValue: boolean): void {
    const storedValue = IndexableServiceHelper.getStoredValueAsObject(storageKey);
    const updatedValue = { ...storedValue, [updateKey]: newValue };

    localStorage.setItem(storageKey, JSON.stringify(updatedValue));
  }

  public static updateOutdatedDataInStorage(outdatedEntityKey: string, shouldUpdateNextLoad: boolean): void {
    IndexableServiceHelper.updateStoredValueInObject('updated_key_data', outdatedEntityKey, shouldUpdateNextLoad);
  }
}
