import { Pipe, PipeTransform } from '@angular/core';
import { SortDirection } from '@core/enums/ui-general.enum';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  public transform(data: any[], property: string, direction: SortDirection = SortDirection.asc): any[] {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    const sortedDate = data?.slice().sort((a: any, b: any) => {
      return collator.compare(a[property], b[property]);
    });

    return direction === SortDirection.asc ? sortedDate : sortedDate?.slice().reverse();
  }
}
