import { Component, ElementRef, ViewChild } from '@angular/core';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CsvUploadResponse } from '@core/models/csv-upload-response.model';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { IUploadBase } from '@core/models/upload-base.model';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent {
  @ViewChild('fileInput') public fileInput: ElementRef;
  public errors: { line: string; errors: string[] }[] = [];
  public response: CsvUploadResponse;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  public onFileChange(file: File): void {
    if (file) {
      (this.dialogConfig.data.service as IUploadBase).uploadCsvFiles(file).subscribe((response) => {
        this.errors = [];
        this.response = { filename: file.name, ...response };
        if (response.errors && Object.keys(response.errors).length) {
          for (const lineNumber in response.errors) {
            if (response.errors.hasOwnProperty(lineNumber)) {
              this.errors = [...this.errors, { line: lineNumber, errors: response.errors[lineNumber] }];
            }
          }
        } else {
          this.notificationService.success(
            this.translateService.instant('dxp.upload.title'),
            this.translateService.instant('dxp.price-rate.notification.success'),
          );
        }
      });
    }
  }
}
