export class SafariHelper {
  public static checkIsSafariBrowser(date: unknown): unknown {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let newDate = date;

    if (isSafari && newDate && !(newDate instanceof Date)) {
      newDate = (newDate as string).replace(/ /g, 'T');
    }

    return newDate;
  }
}
