import { Component, ContentChildren, Input, QueryList, ViewChild } from '@angular/core';
import { InfoTableColumn } from './base/info-table-column.model';
import { InfoTableColumnType } from './base/info-table-column-type.enum';
import { InfoTableConfigService } from './services/info-table-config.service';
import { Observable } from 'rxjs';
import { InfoTableConfig } from './base/info-table-config.model';
import { Table } from 'primeng/table';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
})
export class InfoTableComponent {
  @Input() public data: any[];
  @Input() public styleClass: string;
  @Input() public columns: InfoTableColumn[];
  @Input() public footer: InfoTableColumn[];

  @ContentChildren(CapturumTemplateDirective) public templates: QueryList<any>;
  @ViewChild(Table) public primeNGTable: Table;
  public DataTableType: typeof InfoTableColumnType = InfoTableColumnType;

  public readonly config$: Observable<InfoTableConfig>;

  constructor(private readonly infoTableConfigService: InfoTableConfigService) {
    this.config$ = this.infoTableConfigService.getConfig();
  }

  public trackByFn(index: number, column: InfoTableColumn): string {
    return column.field;
  }
}
