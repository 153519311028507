import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getOption',
})
export class GetOptionPipe implements PipeTransform {
  public transform(value: string, options: any[], property = 'value'): any {
    const option = (options || []).find((item) => {
      return item[property] === value;
    });

    return option ? option : null;
  }
}
