import { Pipe, PipeTransform } from '@angular/core';
import { ButtonType } from '../services/layout-config.service';

/**
 * This pipe return debounceTime by button type
 *
 * @example
 *
 *  [debounceTime]="myDebounceTime | debounceTimeByType: button.type : 800"
 *
 */

@Pipe({
  name: 'debounceTimeByType',
})
export class DebounceTimeByTypePipe implements PipeTransform {
  public transform(debounceTime: number, buttonType: ButtonType, defaultDebounceTime = 500): number {
    if (debounceTime) {
      return debounceTime;
    }

    if (buttonType) {
      const typesWithDebounceTime = [ButtonType.SAVE, ButtonType.SUBMIT, ButtonType.ADD, ButtonType.DEFAULT];

      return typesWithDebounceTime.includes(buttonType) ? defaultDebounceTime : 0;
    }

    return 0;
  }
}
