import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InfoTableConfig } from '../base/info-table-config.model';

@Injectable({
  providedIn: 'root',
})
export class InfoTableConfigService {
  private defaultConfig: InfoTableConfig = {
    texts: {
      positive: 'Yes',
      negative: 'No',
    },
  };

  private config: BehaviorSubject<InfoTableConfig> = new BehaviorSubject<InfoTableConfig>(this.defaultConfig);

  public setConfig(config: Partial<InfoTableConfig>): void {
    this.config.next({ ...this.defaultConfig, ...config });
  }

  public getConfig(): Observable<InfoTableConfig> {
    return this.config.asObservable();
  }
}
