export enum FormAction {
  delete = 'delete',
  submit = 'submit',
  confirm = 'confirm',
  cancel = 'read',
  applyFilter = 'apply_filter',
  add = 'add',
  save = 'save',
  close = 'close',
}
