import { Pipe, PipeTransform } from '@angular/core';
import { DataState } from '@core/enums/ui-general.enum';

@Pipe({
  name: 'isDataState',
})
export class IsDataStatePipe implements PipeTransform {
  public transform(value: string, state: DataState): boolean {
    return value === state;
  }
}
