<div class="d-inline-block">
  <div class="navigation-button">
    <div (mousedown)="$event.preventDefault(); navigate?.emit(navigateDirection.prev)" class="navigation-button__back">
      <i class="fas fa-chevron-left"></i>
    </div>

    <div class="navigation-button__title">
      {{ title }}
      <ng-content></ng-content>
    </div>

    <div (mousedown)="$event.preventDefault(); navigate?.emit(navigateDirection.next)" class="navigation-button__next">
      <i class="fas fa-chevron-right"></i>
    </div>
  </div>
</div>
