import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { Farm } from '@core/models/farm.model';
import { debounceTime, distinctUntilChanged, first, map, takeUntil, tap } from 'rxjs/operators';
import { ConverterUtil } from '@core/utils/converter-util';
import User from '@core/models/user.model';
import { SetActiveFarm } from '@store/admin/general/general.actions';
import { FarmService } from '@core/api/farm.service';
import { UserService } from '@core/api/user.service';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DxpIndexablePermissionService } from '@core/indexDb/services/dxp-indexable-permission.service';
import { Store } from '@ngxs/store';
import { DestroyBase } from '@core/base/destroy.class';
import { GeneralSelectors } from '@store/admin/general/general.selectors';
import { SortDirection } from '@core/enums/ui-general.enum';
import { ListOptions } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { AppRoutes } from '@core/enums/routes.enum';

@Component({
  selector: 'app-switch-farm-list',
  templateUrl: './switch-farm-list.component.html',
  styleUrls: ['./switch-farm-list.component.scss'],
})
export class SwitchFarmListComponent extends DestroyBase implements OnInit {
  public activeFarm$: Observable<Farm>;
  public searchControl: UntypedFormControl = new UntypedFormControl();
  public farms$: Observable<MapItem[]>;
  @Output() public farmChange: EventEmitter<Farm> = new EventEmitter();

  private farms: Farm[];

  constructor(
    private readonly farmService: FarmService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly dxpIndexablePermissionService: DxpIndexablePermissionService,
    private readonly store: Store,
  ) {
    super();

    this.activeFarm$ = this.store.select(GeneralSelectors.getActiveFarm);
  }

  public ngOnInit(): void {
    const lastRoute = localStorage.getItem('lastRoute');

    this.search();
    // TODO: Temporary solution, should be investigated in BP (builders)
    if (
      lastRoute === `/${AppRoutes.admin}/${AppRoutes.fulfillment}/${AppRoutes.overview}` ||
      lastRoute === `/${AppRoutes.admin}/${AppRoutes.fulfillment}`
    ) {
      setTimeout(() => {
        this.farms$ = this.getFarms();
      }, 0);
    } else {
      this.farms$ = this.getFarms();
    }
  }

  public search(): void {
    this.searchControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroy$))
      .subscribe((search) => {
        const options: ListOptions = search ? { search: [search] } : {};

        this.farms$ = this.getFarms(options);
      });
  }

  public changeFarm(farm: MapItem): void {
    const activeFarmId = this.store.selectSnapshot(GeneralSelectors.getActiveFarm)?.id;

    if (farm.value !== activeFarmId) {
      this.farmService.switchActiveFarm({ farm_id: farm.value }).subscribe((response) => {
        const user = this.authService.getUser() as User;
        const activeFarm = this.farms.find((item) => {
          return item?.id === farm?.value;
        });

        user.activeFarm = activeFarm;

        this.store.dispatch(new SetActiveFarm(activeFarm));
        localStorage.setItem('user', JSON.stringify(user));

        this.dxpIndexablePermissionService
          .setIndexedData(response?.data)
          .pipe(first())
          .subscribe(() => {
            this.dxpIndexablePermissionService.reIndexPermissions(response?.data);

            this.notificationService.success(
              this.translateService.instant('toast.success.title'),
              this.translateService.instant('dxp.farm-switch.success'),
            );

            this.farmChange.emit(activeFarm);
          });
      });
    }
  }

  private getFarms(options: ListOptions = {}): Observable<MapItem[]> {
    const defaultOptions: ListOptions = {
      perPage: 5,
      sort: [{ field: 'name', direction: SortDirection.asc }],
      ...options,
    };

    return this.userService.farms(defaultOptions).pipe(
      tap((results) => {
        return (this.farms = results?.data);
      }),
      map((results) => {
        return ConverterUtil.indexToOption(results);
      }),
    );
  }
}
