<div class="w-max-content py-1">
  <cap-button
    styleClass="secondary rounded-pill me-md-2 mb-2"
    [attr.data-test]="entity | e2eAttr: FormAction.delete"
    [label]="'dxp.button.reset-filter' | translate"
    (onClick)="resetFilter.emit()">
  </cap-button>

  <cap-button
    styleClass="primary rounded-pill"
    [attr.data-test]="entity | e2eAttr: FormAction.applyFilter"
    [label]="applyLabel | translate"
    (onClick)="applyFilter.emit()">
  </cap-button>
</div>
