@if (flight?.shipping?.arrival_delay) {
  <div class="d-flex align-items-center">
    <i class="fas fa-clock"></i>
    <span class="m-1">{{ 'dxp.logistics.delayed.label' | translate }}:</span>
    <span class="mr-2">{{ flight.shipping?.estimated_arrival_at | defaultDateFormatting }}</span>
  </div>
}

@if (!flight?.shipping?.arrival_delay) {
  <div>
    <span>{{ 'dxp.logistics.on-time.label' | translate }}</span>
  </div>
}
