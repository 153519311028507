import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-badge-button',
  styleUrls: ['badge-button.scss'],
  templateUrl: 'badge-button.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeButtonComponent {
  @Input() public icon: string;
  @Input() public badgeValue: string | number;
  @Input() public isLightTheme: boolean;
  @Input() public rotate: boolean;
  @Input() public proportions = 35;
  @Input() public warning = false;

  @Output() public clicked: EventEmitter<any> = new EventEmitter<any>();
}
