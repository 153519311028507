import { IndexedDbModel } from '@capturum/complete';
import { ObservationType } from '@core/enums/observation-type.enum';

export class IndexDbObservation extends IndexedDbModel {
  // Configuration
  public entity = 'observation';
  public table = 'observations';
  public schema = 'id';
  public sync = true;

  // Fields
  public observationable_type: ObservationType;
  public observationable_id: string;
  public type_base_data_value_id: string;
  public value: string;
  public lat: number;
  public lon: number;
  public remark: string;
  public files: { filename: string; data: string }[];

  // Constructor
  constructor(attributes: any) {
    super(attributes);
  }
}
