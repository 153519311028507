import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataApiModel, CompleteConfig, IndexedDbGuard, IndexedDbService } from '@capturum/complete';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapItem } from '@capturum/ui/api';
import { NavigationService } from '@core/services/navigation.service';
import { AuthService } from '@capturum/auth';
import { IndexableDataService } from '@core/indexDb/services/dxp-indexable-data.service';
import { CacheService } from '@core/services/cache.service';

/***
 * We're still going to need this service to supply it to @Emendis/Complete
 */

@Injectable({
  providedIn: 'root',
})
export class DxpIndexableBaseDataValueService extends IndexableDataService<BaseDataApiModel[]> {
  protected endpoint = 'base-data-value';
  protected outdatedEntityKey = 'base_data_value';

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly cacheService: CacheService,
    protected readonly navigationService: NavigationService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
  ) {
    super(api, translateService, authService, completeConfig, indexedDbService, indexedDbGuard);
  }

  public loadBaseDataValues(forceReload = false): Observable<any> {
    return this.getData(forceReload);
  }

  public onFatalError(): Observable<void> {
    return super.onFatalError().pipe(
      map(() => {
        this.cacheService.forceLogout();
      }),
    );
  }

  /**
   * Return a list of base-data-values filtered by key and optionally translated
   * Important: This method is used in @Emendis/complete, so don't just remove it because it appears unused in MRP
   *
   * @param key - the base-data-key
   * @param translate - Whether or not to translate the values
   */
  public getListValuesByKey(key: string, translate = true, field = 'key'): Observable<MapItem[]> {
    return this.list({ filters: [{ field, value: key }] }).pipe(
      map((response) => {
        return response?.data.map((value) => {
          return {
            value: value.key,
            label: translate ? this.translateService.instant(`base-data.${value.key.toLowerCase()}`) : value.label,
          } as MapItem;
        });
      }),
    );
  }
}
