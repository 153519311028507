import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
})
export class OverviewCardComponent {
  @Input() public headerTemplate: TemplateRef<any>;
  @Input() public bodyTemplate: TemplateRef<any>;
  @Input() public actionTemplate: TemplateRef<any>;
  @Input() public showAction = false;
  @Input() public actionLabel: string;
  @Input() public actionIcon: string;
  @Input() public styleClass = '';

  @Output() public actionClick: EventEmitter<void> = new EventEmitter<void>();

  public triggerAction(): void {
    this.actionClick.emit();
  }
}
