import { Component, Input } from '@angular/core';
import { DynamicAccordionItemHeader } from '@shared/modules/dynamic-accordion/models/dynamic-accordion-item';

@Component({
  selector: 'app-dynamic-accordion-header',
  templateUrl: './dynamic-accordion-header.component.html',
})
export class DynamicAccordionHeaderComponent {
  @Input()
  public content: DynamicAccordionItemHeader;
}
