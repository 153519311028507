import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConnectivityService } from '@capturum/complete';
import { DestroyBase } from '@core/base/destroy.class';
import { takeUntil } from 'rxjs/operators';
import { HarvestService } from '@core/api/harvest.service';
import { ObservationService } from '@core/api/observation.service';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetStoredAmount } from '@store/mobile/sync/sync.actions';
import { SyncSelectors } from '@store/mobile/sync/sync.selectors';
import { AppRoutes } from '@core/enums/routes.enum';

@Component({
  selector: 'app-synchronization',
  templateUrl: './synchronization.component.html',
  styleUrls: ['./synchronization.component.scss'],
})
export class SynchronizationComponent extends DestroyBase implements OnInit {
  public inProgress: boolean;
  public AppRoutes: typeof AppRoutes = AppRoutes;
  public isOnline$: Observable<boolean>;
  @Output() public syncClicked: EventEmitter<any> = new EventEmitter();
  public storedItems$: Observable<number>;
  public inProgress$: Observable<boolean>;
  public existsSyncErrors$: Observable<boolean>;

  constructor(
    private connectivityService: ConnectivityService,
    private harvestService: HarvestService,
    private observationService: ObservationService,
    private store: Store,
  ) {
    super();

    this.storedItems$ = this.store.select(SyncSelectors.getAmount);
    this.inProgress$ = this.store.select(SyncSelectors.getProgressStatus);
    this.existsSyncErrors$ = this.store.select(SyncSelectors.existsSyncErrors);
  }

  public ngOnInit(): void {
    this.isOnline$ = this.connectivityService.isOnline();
    this.fetchSynchronizingStatus();
    this.calculateNumberOfStoredItem();
  }

  private calculateNumberOfStoredItem(): void {
    forkJoin([this.observationService.getTotalAmount(), this.harvestService.getTotalAmount()]).subscribe(
      (result: number[]) => {
        const calculatedAmount = result.reduce((acc, amount) => {
          return acc + amount;
        }, 0);

        this.store.dispatch(new SetStoredAmount(calculatedAmount));
      },
    );
  }

  private fetchSynchronizingStatus(): void {
    this.inProgress$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      return (this.inProgress = status);
    });
  }
}
