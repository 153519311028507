import { ModelLoggableType } from '@core/enums/model-loggable-type.enum';

export class EntityUtil {
  public static getEntityKey(modelType: string, translationKey?: boolean): string {
    if (modelType) {
      const stringParts = modelType.split('\\');
      const key = stringParts[stringParts?.length - 1];
      const entityKey = ModelLoggableType[key] || key;

      if (translationKey) {
        return `dxp.${entityKey}.entity_name`;
      }

      return entityKey;
    }
  }
}
