import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList, TemplateRef } from '@angular/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { SubMenuConfig } from '@core/models/mobile-nav-config.model';
import { Observable } from 'rxjs';
import { CapturumTemplateDirective } from '@capturum/ui/api';
import { ResponsiveService } from '@shared/services/responsive.service';

@Component({
  selector: 'app-details-screen',
  templateUrl: './details-screen.component.html',
  styleUrls: ['./details-screen.component.scss'],
})
export class DetailsScreenComponent implements OnDestroy, AfterContentInit {
  public routes: typeof AppRoutes = AppRoutes;
  public isMobile = false;
  public firstTemplate: TemplateRef<string>;
  public secondTemplate: TemplateRef<string>;
  public thirdTemplate: TemplateRef<string>;
  public buttonsTemplate: TemplateRef<string>;

  @Input() public title: string;
  @Input() public subMenuConfig: SubMenuConfig;
  @Input() public navTabItems: Observable<string[]>;
  @Input() public noBorder = false;

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private responsiveService: ResponsiveService,
  ) {
    this.initMobile();
  }

  public ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'menuButtons':
          this.buttonsTemplate = item.template;
          break;
        case 'first':
          this.firstTemplate = item.template;
          break;
        case 'second':
          this.secondTemplate = item.template;
          break;
        case 'third':
          this.thirdTemplate = item.template;
          break;
        default:
          break;
      }
    });
  }

  public ngOnDestroy(): void {
    this.layoutUtilsService.hideMobileSubMenu();
  }

  private initMobile(): void {
    this.isMobile = this.getWindowWidth() < 768;

    window.addEventListener('resize', () => {
      this.isMobile = this.getWindowWidth() < 768;
    });
  }

  private getWindowWidth(): number {
    return ResponsiveService.getWindowWidth();
  }
}
