<div class="keyboard">
  <div class="keyboard__input">
    <cap-input
      [inputValue]="inputValue"
      [disabled]="disabled"
      [placeholder]="0"
      type="number"
      styleClass="input-lg"></cap-input>
  </div>
  <div class="keyboard__container">
    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="7" (onClick)="onBtnPress('7')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="8" (onClick)="onBtnPress('8')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="9" (onClick)="onBtnPress('9')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="4" (onClick)="onBtnPress('4')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="5" (onClick)="onBtnPress('5')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="6" (onClick)="onBtnPress('6')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="1" (onClick)="onBtnPress('1')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="2" (onClick)="onBtnPress('2')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn" label="3" (onClick)="onBtnPress('3')">
    </cap-button>

    <cap-button styleClass="primary w-100 h-100" class="keyboard__btn--zero" label="0" (onClick)="onBtnPress('0')">
    </cap-button>

    <cap-button
      styleClass="secondary w-100 h-100"
      class="keyboard__btn--delete"
      icon="fas fa-backspace"
      (onClick)="onDeletePress()">
    </cap-button>
  </div>
</div>
