import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Entity } from '@core/enums/entity.enum';
import { FormAction } from '@core/enums/form-action.enum';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormDialogComponent {
  @Input() public entityName: Entity;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public title: string;
  @Input() public formTemplate: TemplateRef<string>;
  @Input() public visible: boolean;
  @Input() public bodyTitle: string;
  @Input() public styleClass: string;
  @Input() public buttonCancelLabel: string;
  @Input() public buttonDeleteLabel: string;
  @Input() public buttonForceDeleteLabel: string;
  @Input() public showInlineWarning: boolean;
  @Input() public warnings: { title: string; message: string }[] = [];
  @Input() public minEndsDate: Date = new Date();
  @Output() public canceled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public submitted: EventEmitter<{ value: Date; force?: boolean }> = new EventEmitter<{
    value: Date;
    force?: boolean;
  }>();

  public FormAction = FormAction;
  public defaultDate: Date = new Date();
  public calendarControl: UntypedFormControl = new UntypedFormControl(new Date());

  public submit(force?: boolean): void {
    if (this.calendarControl.value) {
      this.submitted.emit({ value: new Date(this.calendarControl.value), force });
    }
  }
}
