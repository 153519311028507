import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'baseDataValue',
})
export class BaseDataValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: string): string {
    return value ? this.translateService.instant(`base-data.${value}`) : '';
  }
}
