import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { Breakpoints } from '@angular/cdk/layout';
import { MasonryBlockDirective } from '@shared/directives/masonry-block.directive';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { DestroyBase } from '@core/base/destroy.class';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { BreakpointService } from '@core/services/breakpoint.service';

/**
 * This is a lyout component to create a masonry-like layout
 *
 * To use this layout you have to add 1 or more elements with the appMasonryBlock directive.
 * Every element with this directive is a block. The order of this block can be defined with this directive
 *
 * @example
 *
 * <app-masonry>
 *   <div class="my-element-1" *appMasonryBlock="1"></div>
 *
 *   <div class="my-element-2" *appMasonryBlock="{ tablet: 3, desktop: 2, mobile: 2 }"></div>
 *
 *   <div class="my-element-3" *appMasonryBlock="{ tablet: 2, desktop: 3, mobile: 3 }"></div>
 * </app-masonry>
 *
 * In this example You can see that there are 3 blocks. And the first block will always be positioned as the first element in the layout.
 * The other 2 components' position is dependent on what screeensize is active.
 */
@Component({
  selector: 'app-masonry',
  templateUrl: './masonry.component.html',
  styleUrls: ['./masonry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasonryComponent extends DestroyBase implements AfterContentInit {
  @ContentChildren(MasonryBlockDirective, { descendants: true }) public blocks: QueryList<MasonryBlockDirective>;
  @Input() public columns = 2;
  @Input() public breakpoint: string[] = [Breakpoints.TabletPortrait, Breakpoints.Handset];

  public showMasonryLayout = true;
  public orderedBlocks: any[][] = [];
  public template: TemplateRef<any>;
  public sortedBlocks: MasonryBlockDirective[];

  private currentScreenSize: ScreenSize = null; // Trigger first subscription

  constructor(
    private cdr: ChangeDetectorRef,
    private breakPointService: BreakpointService,
  ) {
    super();
  }

  public ngAfterContentInit(): void {
    this.breakPointService
      .getScreenSize()
      .pipe(
        distinctUntilChanged(),
        filter((screenSize) => {
          return screenSize !== this.currentScreenSize;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((screenSize: ScreenSize) => {
        this.currentScreenSize = screenSize;
        this.orderedBlocks = [];
        this.orderBlocks(screenSize);

        this.cdr.detectChanges();
      });
  }

  public orderBlocks(size?: ScreenSize): void {
    if (this.blocks && this.blocks.length) {
      this.sortedBlocks = this.blocks.toArray().sort((block, block2) => {
        const order1 = this.getOrderProperty(size, block);
        const order2 = this.getOrderProperty(size, block2);

        return order1 - order2;
      });

      for (let i = 0; i < this.columns; i++) {
        this.orderedBlocks[i] = this.sortedBlocks.filter((item) => {
          return this.getOrderProperty(size, item) % this.columns === (i + 1) % this.columns;
        });
      }
    }
  }

  private getOrderProperty(size: ScreenSize, block: MasonryBlockDirective): number {
    return typeof block.order === 'number'
      ? block.order
      : block.order[size] ||
          block.order[ScreenSize.desktop] ||
          block.order[ScreenSize.tablet] ||
          block.order[ScreenSize.mobile];
  }
}
