import { Injectable } from '@angular/core';
import { ApiHttpService, ListOptions } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import {
  BaseDataApiModel,
  BaseDataIndexedDbModel,
  BaseDataValueApi,
  BaseTranslation,
  CompleteConfig,
  IndexedDbGuard,
  IndexedDbService,
} from '@capturum/complete';
import { Observable } from 'rxjs';
import { BaseKey } from '@core/enums/base-key.enum';
import { MapItem } from '@capturum/ui/api';
import { map, shareReplay, tap } from 'rxjs/operators';
import { DxpIndexableBaseDataValueService } from '@core/indexDb/services/dxp-indexable-base-data-value.service';
import { DxpIndexableBaseDataKeyService } from '@core/indexDb/services/dxp-indexable-base-data-key.service';
import { NavigationService } from '@core/services/navigation.service';
import { IndexableDataService } from '@core/indexDb/services/dxp-indexable-data.service';
import { CacheService } from '@core/services/cache.service';
import { BaseDataAttributes, MapItemAttribute } from '@core/models/map-item-attribute.model';
import { SortDirection } from '@core/enums/ui-general.enum';

@Injectable({
  providedIn: 'root',
})
export class DxpIndexableBaseDataService extends IndexableDataService<BaseDataApiModel[]> {
  protected outdatedEntityKey = 'base_data';

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly dxpIndexableBaseDataValueService: DxpIndexableBaseDataValueService,
    protected readonly dxpIndexableBaseDataKeyService: DxpIndexableBaseDataKeyService,
    protected readonly navigationService: NavigationService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
    protected readonly cacheService: CacheService,
  ) {
    super(api, translateService, authService, completeConfig, indexedDbService, indexedDbGuard);

    this.endpoint = `base-data-key`;

    this.defaultApiOptions = {
      include: ['attributes', 'values.attributes', 'values.translations'],
      perPage: 1000,
    };
  }

  /**
   * I would deprecate this method, but @Emendis/complete components rely on it
   */
  public loadBaseData(forceReload = false): Observable<any> {
    return this.getData(forceReload);
  }

  public onFatalError(): Observable<void> {
    return super.onFatalError().pipe(
      tap(() => {
        this.cacheService.forceLogout();
      }),
    );
  }

  /**
   * These methods are updated to re-use indexedData and preferable to use in MRP!
   *
   * Slowly we can start phasing out all methods that don't need to retrieve base data from network
   */
  public getBaseDataByKey(key: BaseKey, forceNetworkRequest = false): Observable<BaseDataIndexedDbModel> {
    return this.getData(forceNetworkRequest).pipe(
      map((model: BaseDataIndexedDbModel[]) => {
        return model?.find((baseData) => {
          return baseData.key === key;
        });
      }),
    );
  }

  public getBaseDataById(key: BaseKey, id: string): Observable<BaseDataValueApi> {
    return this.getBaseDataByKey(key).pipe(
      map((model: BaseDataIndexedDbModel) => {
        return model?.values?.find((baseDataValue) => {
          return baseDataValue.id === id;
        });
      }),
    );
  }

  public getBaseDataOptions(key: BaseKey): Observable<MapItem[]> {
    const user = this.authService.getUser();
    const localeId = user ? user.locale_id : null;

    return this.getBaseDataByKey(key).pipe(
      map((baseData: BaseDataIndexedDbModel) => {
        return baseData?.values;
      }),
      map((baseData: BaseDataValueApi[]) => {
        // TODO: When do we need a parent?
        return this.getMapItems(localeId, baseData, false);
      }),
    );
  }

  /**
   * These came from key service
   */
  public getMapItems(
    localeId: string | number,
    values: BaseDataValueApi[],
    parents: boolean,
    parentValue?: string,
  ): MapItem[] {
    let baseList = values;

    if (parents) {
      baseList = values.filter((item) => {
        return item.parent_id === null;
      });
    } else if (parentValue) {
      baseList = values.filter((item) => {
        return item.parent_id === parentValue;
      });
    }

    if (baseList) {
      return baseList.map((item) => {
        return this.getBaseMapItem(item, localeId);
      });
    }
  }

  public getBaseMapItem(baseItem: BaseDataValueApi, localeId: string | number): MapItem {
    const translate = this.getTranslation(localeId, baseItem?.translations)?.translation;

    return {
      label: this.getTranslation(localeId, baseItem?.translations) ? translate : baseItem?.value,
      value: baseItem?.id,
      key: baseItem?.value,
    };
  }

  public getTranslation(localeId: string | number, translations: BaseTranslation[]): BaseTranslation {
    return translations.find((item) => {
      return item.locale_id === localeId;
    });
  }

  public getConvertedBaseDataValues(options: ListOptions): Observable<MapItem[]> {
    return this.dxpIndexableBaseDataValueService.index(options).pipe(
      map((response) => {
        return response.data.map((item: any) => {
          return this.dxpIndexableBaseDataKeyService.getBaseMapItem(item, this.authService.getUser()?.locale_id);
        });
      }),
    );
  }

  public getBaseDataValuesByDataKey(key: BaseKey): Observable<MapItem[]> {
    return this.getBaseDataByKey(key).pipe(
      map((baseDataIndexedDbModel) => {
        return baseDataIndexedDbModel?.values.map((item: BaseDataValueApi) => {
          return {
            label: this.translateService.instant(`base-data.${item.id}`),
            value: item.id,
            key: item.value,
          } as MapItem;
        });
      }),
      shareReplay(1),
    );
  }

  public getBaseDataValuesWithAttribute(key: string, options?: ListOptions): Observable<MapItemAttribute[]> {
    const defaultOptions: ListOptions = {
      perPage: 999,
      include: ['propertyValues'],
      filters: [{ field: 'key.key', value: key }],
      sort: [{ field: 'propertyValues.value', direction: SortDirection.asc }],
    };

    return this.dxpIndexableBaseDataValueService.index<BaseDataApiModel>({ ...defaultOptions, ...options }).pipe(
      map((response) => {
        return response?.data?.map((baseDataValue: BaseDataApiModel & { propertyValues: BaseDataAttributes[] }) => {
          return {
            value: baseDataValue.id,
            key: baseDataValue.value,
            label: this.translateService.instant(`base-data.${baseDataValue.id.toLowerCase()}`),
            attributes: baseDataValue.propertyValues,
          };
        });
      }),
    );
  }

  public getWeightedBaseDataKeyValues(key: string, options?: ListOptions): Observable<MapItem[]> {
    return this.getBaseDataValuesWithAttribute(key, options).pipe(
      map((values) => {
        return values.sort((a, b) => {
          const ra = this.getWeight(a);
          const rb = this.getWeight(b);

          return ra - rb;
        });
      }),
    );
  }

  public getBaseDataAttributesValues(key: string, options?: ListOptions): Observable<MapItem[]> {
    return this.getBaseDataValuesWithAttribute(key, options).pipe(
      map((values) => {
        return values.map((item) => {
          return {
            label: item.attributes[0].value,
            value: item.value,
          } as MapItem;
        });
      }),
      shareReplay(1),
    );
  }

  private getWeight(item: MapItemAttribute): number {
    if (item.attributes?.length) {
      const firstAttr = item.attributes[0].hasOwnProperty('weight');

      if (firstAttr) {
        return Number(item.attributes[0].weight);
      }
    }

    return Number.MAX_VALUE;
  }
}
