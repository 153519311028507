import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MobileNavService } from '@core/services/mobile-nav.service';
import { MobilelayoutConfig, MobileNavConfig } from '@core/models/mobile-nav-config.model';

// TODO: DEPRECATED, after mobile update

@Injectable({ providedIn: 'root' })
export class LayoutUtilsService {
  public isMobileHeaderSubMenuShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public subMenuTabSelected$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public subMenuTabs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private mobNavService: MobileNavService) {}

  public showMobileSubMenu(tabs: string[]): void {
    this.isMobileHeaderSubMenuShown$.next(true);
    this.subMenuTabs$.next(tabs);
  }

  public hideMobileSubMenu(): void {
    this.isMobileHeaderSubMenuShown$.next(false);
  }

  public setNavMenuTabSelected(index: number): void {
    this.subMenuTabSelected$.next(index);
  }

  public isNavTabBarShown(): boolean {
    return this.isMobileHeaderSubMenuShown$.getValue();
  }

  public getCurrentTabSelected(): number {
    return this.subMenuTabSelected$.getValue();
  }

  /**
   * @whatItDoes (Mobile appearance) - Enables and sets up the mobile header appearance, eg page title, navigate back url etc.
   * @param conf {MobilelayoutConfig} - Header Config
   */
  public configureNavBar(conf: MobilelayoutConfig = {}): void {
    const config: MobileNavConfig = {
      routerUrl: conf.url,
      leftSide: { icon: 'fas fa-chevron-left', url: conf.backUrl },
      centerSide: { title: !(conf.pageTitle instanceof Observable) ? of(conf.pageTitle) : conf.pageTitle },
    };

    this.mobNavService.addConfig(config);
  }

  /**
   * @whatItDoes (Mobile appearance) - Configure header to show page title and setup navigate back icon router url,
   *  enables and setup nav tab items if given
   * @param config {MobilelayoutConfig} - Mobile Header configuration eg: page title, navigate back url etc.
   * @param navItems {string[]} [Optional] - Sub header nav tabs titles. Enables the subNav if set
   */
  public showSubMenuAndConfigureNavBar(config: MobilelayoutConfig, navItems: string[] = []): void {
    this.showMobileSubMenu(navItems);
    this.configureNavBar(config);
    this.subMenuTabSelected$.next(0);
  }

  /**
   * @whatItDoes (Mobile appearance) - Removes sub-header navigation tabs and configures mobile header to show the label only
   */
  public hideNaveMenuAndFlushNavBar(): void {
    this.hideMobileSubMenu();
    this.configureNavBar();
  }

  public flushAllConfiguration(): void {
    this.hideNaveMenuAndFlushNavBar();
  }
}
