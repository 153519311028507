import { Component, Input } from '@angular/core';
import { DialogAccordionItemBody } from '@shared/modules/dynamic-accordion/models/dynamic-accordion-item';

@Component({
  selector: 'app-dynamic-accordion-body-address',
  templateUrl: './dynamic-accordion-body-address.component.html',
})
export class DynamicAccordionBodyAddressComponent {
  @Input()
  public content: DialogAccordionItemBody[];
}
