import { Component, Input } from '@angular/core';
import { OriginModel } from '@core/models/origin.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-origin-card',
  templateUrl: './origin-card.component.html',
  styleUrls: ['./origin-card.component.scss'],
})
export class OriginCardComponent {
  @Input() public origin: Observable<OriginModel>;
}
