@if (actionItems.length) {
  <div [class]="['primary', 'menu-action', 'square', styleClass] | join: ' '" (capClickOutside)="isOpen = false">
    <i class="fas fa-ellipsis-v" (click)="toggleMenu()"></i>
    <ul class="menu-action__items" [class.menu-action__items--open]="isOpen">
      @for (action of actionItems; track action) {
        <ng-container *ngxPermissionsOnly="action.permissions">
          <li
            [attr.data-test]="action?.label"
            [class.disabled]="action.disabled || false"
            (click)="executeAction(action)">
            <div class="menu-action__icon">
              <i [class]="action?.icon"></i>
            </div>
            <div class="menu-action__info">
              <p class="title">{{ action?.label | translate }}</p>
            </div>
          </li>
        </ng-container>
      }
    </ul>
  </div>
}
