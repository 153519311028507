import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '@core/enums/entity.enum';
import { CrudAction } from '@core/enums/crud-action.enum';
import { FormAction } from '@core/enums/form-action.enum';
import { ButtonType } from '@shared/services/layout-config.service';

@Pipe({
  name: 'e2eAttr',
})
export class E2eAttrPipe implements PipeTransform {
  public transform(
    entityName: Entity,
    action: CrudAction | FormAction | ButtonType,
    secondAction?: CrudAction | FormAction | ButtonType,
  ): string {
    if (secondAction) {
      return `${action}_${secondAction}_${entityName}`;
    } else {
      return `${action}_${entityName}`;
    }
  }
}
