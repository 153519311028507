import { MapItem } from '@capturum/ui/api';
import { ApiSingleResult } from '@capturum/api';
import moment from 'moment';
import { DatesFormats } from '@core/configs/date-config';
import { BaseDataValueApi } from '@capturum/complete';
import { MapItemAttribute } from '@core/models/map-item-attribute.model';
import { FilterMetadata } from 'primeng/api';
import { Address } from '@core/models/address.model';

export class ConverterUtil {
  public static listToOption(items: { key: string; label: string }[]): MapItem[] {
    return items?.map((item: any) => {
      return { label: item?.label, value: item?.key };
    });
  }

  public static customObjectToOption<T>(
    response: T[],
    label: string[] = ['name'],
    value = 'id',
    delimiter = ' ',
  ): MapItem[] {
    return response?.map((item) => {
      return {
        label: label
          .reduce((acc, labelField) => {
            return acc + `${item[labelField] ? item[labelField] + delimiter : ''}`;
          }, '')
          .slice(0, -delimiter?.length),
        value: item[value],
      };
    });
  }

  public static indexToOption(response: ApiSingleResult<any>, label = 'name', value = 'id'): MapItem[] {
    return response?.data?.map((item) => {
      return {
        label: item[label],
        value: item[value],
      };
    });
  }

  public static baseDataAsMapItem(baseData: BaseDataValueApi): MapItem {
    return {
      label: baseData?.value,
      value: baseData?.id,
    } as MapItem;
  }

  public static baseDataAsMapItems(baseData: BaseDataValueApi[]): MapItem[] {
    return baseData.map((item) => {
      return {
        label: item?.value,
        value: item?.id,
      } as MapItem;
    });
  }

  public static baseDataAsMapItemAttributes(baseData: BaseDataValueApi[]): MapItemAttribute[] {
    return baseData.map((item) => {
      return {
        label: item.value,
        value: item.id,
        attributes: item.attributes,
      } as MapItemAttribute;
    });
  }

  public static parseListFilters(filters: {
    [key: string]: FilterMetadata;
  }): { field: string; value: any; operator?: string }[] {
    return Object.keys(filters).map((key) => {
      if (Array.isArray(filters[key].value)) {
        if (
          filters[key].value.every((value) => {
            return value instanceof Date;
          })
        ) {
          filters[key].value[0] = moment(filters[key].value[0]).startOf('day').format(DatesFormats.sendFormat);
          filters[key].value[1] = moment(filters[key].value[0]).endOf('day').format(DatesFormats.sendFormat);
        }
      }

      return {
        field: key,
        value: filters[key].value,
        operator: filters[key].matchMode,
      };
    });
  }

  public static combineAddressInformation(address: Address, addressType: string): MapItem & { addressTypeId: string } {
    const reducedAddress: Partial<Address> = {
      location_name: address?.location_name,
      addressline_1: address?.addressline_1,
      city: address?.city,
      gln: address?.gln,
    };

    const addressLine =
      Object.values(reducedAddress)
        .filter((value) => {
          return !!value;
        })
        .join(' - ') || '-';

    return {
      label: `${addressType}: ${addressLine}`,
      value: address?.id,
      addressTypeId: address?.address_type_base_data_value_id,
    };
  }
}
