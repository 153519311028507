import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationMessage } from '@shared/modules/notification/base/notification.model';

@Injectable()
export class NotificationState {
  public notification: Subject<NotificationMessage> = new Subject<NotificationMessage>();
  public notification$: Observable<NotificationMessage> = this.notification.asObservable();
  public closeById: Subject<string> = new Subject<string>();
}
