import { Selector } from '@ngxs/store';
import { SyncState } from './sync.state';
import { SyncStateModel } from './sync-state.model';
import { SyncError } from '@core/models/sync-error.model';

export class SyncSelectors {
  @Selector([SyncState])
  public static getAmount(state: Partial<SyncStateModel>): number {
    return state.amount;
  }

  @Selector([SyncState])
  public static getProgressStatus(state: Partial<SyncStateModel>): boolean {
    return state.inProgress;
  }

  @Selector([SyncState])
  public static getSyncErrors(state: Partial<SyncStateModel>): SyncError<any>[] {
    return state.syncErrors;
  }

  @Selector([SyncState])
  public static getCurrentSyncError(state: Partial<SyncStateModel>): SyncError<any> {
    return state.currentSyncError;
  }

  @Selector([SyncState])
  public static existsSyncErrors(state: Partial<SyncStateModel>): boolean {
    return !!state.syncErrors.length;
  }
}
