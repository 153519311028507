import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { BaseKey } from '@core/enums/base-key.enum';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';

@Pipe({
  name: 'baseDataOptions',
})
export class BaseDataOptionsPipe implements PipeTransform {
  constructor(private readonly dxpIndexableBaseDataService: DxpIndexableBaseDataService) {}

  public transform(key: keyof typeof BaseKey, weightSorted = false): Observable<MapItem[]> {
    if (weightSorted) {
      return this.dxpIndexableBaseDataService.getWeightedBaseDataKeyValues(BaseKey[key]);
    } else {
      return this.dxpIndexableBaseDataService.getBaseDataValuesByDataKey(BaseKey[key]);
    }
  }
}
