import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { UploadedFile } from '@core/models/uploaded-file.model';

@Injectable({
  providedIn: 'root',
})
export class FileService extends ApiService<UploadedFile> {
  protected endpoint = 'file';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }
}
