<p-dialog
  (onHide)="canceled.emit(true)"
  [blockScroll]="true"
  [closable]="true"
  [(visible)]="visible"
  [closeOnEscape]="true"
  [draggable]="false"
  [modal]="true"
  [styleClass]="styleClass"
  [header]="title || ('dxp.general.delete.modal.title' | translate)">
  <ng-container [ngTemplateOutlet]="formTemplate || defaultTemplate"></ng-container>

  <ng-template #defaultTemplate>
    <div class="form-dialog">
      @if (bodyTitle) {
        <div class="bodyTitle">{{ bodyTitle | translate }}</div>
      }

      <div class="form-group w-100">
        <cap-calendar
          [placeholder]="placeholder || ('button.select' | translate)"
          [label]="label || ('dxp.contact.ends_at.label' | translate)"
          [showWeek]="true"
          [formControl]="calendarControl"
          [minDate]="minEndsDate"
          [defaultDate]="defaultDate"
          [attr.data-test]="'ends_at'"
          #calendar
          dateFormat="dd-mm-yy"
          [hideOnDateTimeSelect]="true"
          appendTo="body">
        </cap-calendar>
      </div>

      @if (showInlineWarning && warnings.length) {
        <div class="my-5">
          @for (warning of warnings; track warning) {
            <app-alert styleClass="form-dialog-alert" [title]="warning.title" [message]="warning.message"> </app-alert>
          }
        </div>
      }

      <div class="row mt-3 dialog-actions">
        <div class="col-12 col-md dialog-actions--row">
          <cap-button
            type="button"
            styleClass="secondary mr-2 mt-2"
            [label]="buttonCancelLabel || 'button.cancel' | translate"
            [attr.data-test]="entityName | e2eAttr: FormAction.cancel"
            (onClick)="canceled.emit(true)">
          </cap-button>

          @if (showInlineWarning) {
            <cap-button
              type="button"
              styleClass="danger no-text-wrap mr-2 mt-2"
              [disabled]="!calendarControl?.value"
              [attr.data-test]="entityName | e2eAttr: FormAction.delete"
              [label]="buttonForceDeleteLabel || 'button.delete' | translate"
              (onClick)="submit(true)">
            </cap-button>
          } @else {
            <cap-button
              type="button"
              styleClass="danger no-text-wrap mr-2 mt-2"
              [disabled]="!calendarControl?.value"
              [attr.data-test]="entityName | e2eAttr: FormAction.delete"
              [label]="buttonDeleteLabel || 'button.delete' | translate"
              (onClick)="submit()">
            </cap-button>
          }
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
