<!--Show preview when we have multiple files as array of objects-->
@if (items && items.length) {
  <div class="preview row items">
    @for (itemForm of items.controls || items; track itemForm) {
      <div [class]="styleClass" [formGroup]="showMode ? mockFormGroup : itemForm">
        @if (itemForm?.value || itemForm; as uploaderItem) {
          <div class="file">
            <div class="file__preview" (click)="cardClick.emit(uploaderItem?.file)">
              <img [alt]="uploaderItem?.filename" [src]="uploaderItem?.file | createObjectUrl" />
            </div>
            <div class="file__attributes">
              @if (showMode) {
                <div class="bold">{{ uploaderItem?.file?.name | default }}</div>
              } @else {
                <cap-input formControlName="filename" [label]="'dxp.product.image_name.label' | translate"> </cap-input>
              }
              @if (haveType) {
                <hr />
                @if (showMode) {
                  <span class="semi-bold">{{ 'dxp.product.image_type.label' | translate }}</span>
                  <div class="mt-2">
                    {{ uploaderItem?.product_file_type_base_data_value_id | baseDataValue | default }}
                  </div>
                } @else {
                  <cap-dropdown
                    appendTo="body"
                    formControlName="product_file_type_base_data_value_id"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    [label]="'dxp.product.image_type.label' | translate"
                    [options]="'productFileType' | baseDataOptions | async">
                  </cap-dropdown>
                }
              }
            </div>
            @if (deletable) {
              <i class="fas fa-trash-alt file--remove" (click)="handleRemoveIconClick($event, uploaderItem?.file)"></i>
            }
          </div>
        }
      </div>
    }
  </div>
}

<!--Show preview when we have only one file as object-->
@if (file && !items.length) {
  <div class="preview row file">
    <div [class]="styleClass" [formGroup]="mockFormGroup">
      <div class="file">
        <div class="file__preview" (click)="cardClick.emit(file)">
          <img [alt]="file.filename" [src]="file.public_url && thumbnail ? thumbnail : (file | createObjectUrl)" />
        </div>
        <div class="file__attributes">
          <div class="bold">{{ file.filename ? file.filename : (file.name | default) }}</div>
          @if (haveType) {
            <hr />
            <span class="semi-bold">{{ 'dxp.product.image_type.label' | translate }}</span>
            <div class="mt-2">{{ file.product_file_type_base_data_value_id | baseDataValue | default }}</div>
          }
        </div>
        @if (deletable) {
          <i class="fas fa-trash-alt file--remove" (click)="handleRemoveIconClick($event, file)"></i>
        }
      </div>
    </div>
  </div>
}
