<section class="dynamic-accordion">
  <p-accordion [expandIcon]="'fa fa-eye'" [collapseIcon]="'fa fa-eye-slash'" [multiple]="false">
    <p-accordionTab>
      <p-header>
        <ng-content select=".accordion-header"></ng-content>
      </p-header>

      <ng-content select=".accordion-body"></ng-content>
    </p-accordionTab>
  </p-accordion>
</section>
