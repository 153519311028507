import { Pipe, PipeTransform } from '@angular/core';
import { FixedOptionType } from '@core/enums/fixed-option-type.enum';

@Pipe({
  name: 'fixedOptions',
})
export class FixedOptionsPipe implements PipeTransform {
  public transform(type: string): string {
    switch (type) {
      case '1':
        return FixedOptionType.fixedValue;
      case '2':
        return FixedOptionType.farmBased;
      case '3':
        return FixedOptionType.userAdjustable;
      default:
        break;
    }
  }
}
