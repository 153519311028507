import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationService } from '@core/services/navigation.service';
import { Farm } from '@core/models/farm.model';
import { Store } from '@ngxs/store';
import { GeneralSelectors } from '@store/admin/general/general.selectors';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BreakpointService } from '@core/services/breakpoint.service';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { DestroyBase } from '@core/base/destroy.class';
import { CacheService } from '@core/services/cache.service';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent extends DestroyBase implements OnInit {
  public activeFarm$: Observable<Farm>;
  public profileMenuItems$: Observable<MenuItem & { permission?: string[] }[]>;
  @Output() public atAction: EventEmitter<boolean> = new EventEmitter();

  public store = inject(Store);

  constructor(
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private readonly breakPointService: BreakpointService,
    private readonly translateService: TranslateService,
    private readonly cacheService: CacheService,
  ) {
    super();

    this.activeFarm$ = this.store.select(GeneralSelectors.getActiveFarm);
  }

  public ngOnInit(): void {
    this.profileMenuItems$ = this.breakPointService.getScreenSize().pipe(
      // decide which menu items should be shown
      map((screenSize) => {
        if (screenSize === ScreenSize.desktop) {
          return this.createDesktopMenu();
        } else {
          return [...this.createDesktopMenu(), ...this.createTabletMenu()];
        }
      }),
      // add logout option
      map((menus) => {
        return [
          ...menus,
          {
            label: 'dxp.profile-menu.logout.label',
            icon: 'fas fa-sign-out-alt',
            command: () => {
              this.cacheService.forceLogout();
            },
          },
        ];
      }),
    );
  }

  public executeCommand(item: MenuItem): void {
    this.atAction.emit();

    if (item.command) {
      item.command(null);
    } else if (item.routerLink) {
      this.router.navigateByUrl(item.routerLink);
    }
  }

  private createDesktopMenu(): (MenuItem & { permission?: string[] })[] {
    return [
      {
        label: 'dxp.profile-menu.profile.label',
        icon: 'fas fa-user-circle',
        routerLink: `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`,
      },
      {
        label: 'dxp.profile-menu.reload.label',
        icon: 'fas fa-sync',
        command: () => {
          this.cacheService.clearCache(true);
        },
      },
    ] as MenuItem[];
  }

  private createTabletMenu(): (MenuItem & { permission?: string[] })[] {
    return [
      {
        icon: 'fas fa-mobile-alt',
        routerLink: `${AppRoutes.mobile}`,
        label: 'dxp.sidebar.open-harvest-app',
        permission: ['dxp.mobile.app.access'],
      },
      {
        icon: 'fas fa-exclamation-triangle',
        routerLink: `${AppRoutes.mobile}/${AppRoutes.syncErrors}`,
        label: 'dxp.sidebar.sync-errors',
        permission: ['dxp.mobile.app.access'],
      },
    ];
  }
}
