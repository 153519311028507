@if (showMasonryLayout) {
  <div class="pep-masonry">
    <div class="mason-column d-md-flex w-100">
      @for (orderedBlock of orderedBlocks; track orderedBlock) {
        <div class="masonry-tile">
          @for (block of orderedBlock; track block) {
            <div class="mb-2">
              <ng-container [ngTemplateOutlet]="block.templateRef"> </ng-container>
            </div>
          }
        </div>
      }
    </div>
  </div>
}

@if (!showMasonryLayout) {
  <ng-content></ng-content>
}
