import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { TabViewModule } from 'primeng/tabview';
import { CompleteModule } from '@capturum/complete';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CapturumRadioModule } from '@capturum/ui/radio';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CapturumTableModule } from '@capturum/ui/table';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumLayoutModule } from '@capturum/ui/layout';
import { CapturumButtonModule } from '@capturum/ui/button';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { CapturumTimelineModule } from '@capturum/ui/timeline';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CapturumInputMaskModule } from '@capturum/ui/input-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { InfoTableModule } from '@shared/modules/info-table/info-table.module';
import { DynamicAccordionModule } from '@shared/modules/dynamic-accordion/dynamic-accordion.module';
import { RouterModule } from '@angular/router';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';

// COMPONENTS
import { AlertComponent } from './components/alert/alert.component';
import { MasonryComponent } from './components/masonry/masonry.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { OriginCardComponent } from './components/origin-card/origin-card.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { ResetFilterComponent } from './components/reset-filter/reset-filter.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { OverviewCardComponent } from './components/overview-card/overview-card.component';
import { CardBodyRowComponent } from './components/card-body-row/card-body-row.component';
import { DetailCardComponent } from '@shared/components/detail-card/detail-card.component';
import { TileListComponent } from '../features/admin/manage/pages/tile/tile-list.component';
import { BadgeButtonComponent } from '@shared/components/badge-button/badge-button.component';
import { AccordionListComponent } from './components/accordion-list/accordion-list.component';
import { UploadModalComponent } from '@shared/components/upload-modal/upload-modal.component';
import { FilterButtonsComponent } from './components/filter-buttons/filter-buttons.component';
import { AmountInputComponent } from '@shared/components/amount-input/amount-input.component';
import { SkeletonTableComponent } from './components/skeleton-table/skeleton-table.component';
import { HistoryModalComponent } from '@shared/components/history-modal/history-modal.component';
import { SwitchFarmListComponent } from './components/switch-farm-list/switch-farm-list.component';
import { DetailsScreenComponent } from '@shared/components/details-screen/details-screen.component';
import { FilePreviewCardComponent } from './components/file-preview-card/file-preview-card.component';
import { CsvUploadResultComponent } from './components/csv-upload-result/csv-upload-result.component';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';
import { SelectListItemComponent } from '@shared/components/select-list-item/select-list-item.component';
import { SecondaryButtonComponent } from '@shared/components/secondary-button/secondary-button.component';
import { FormlyRepeatTypeComponent } from '@shared/components/formly-repeat-type/formly-repeat-type.component';
import { TimelineModelLogComponent } from '@shared/components/timeline-model-log/timeline-model-log.component';
import { DatePickerButtonComponent } from '@shared/components/date-picker-button/date-picker-button.component';
import { FontAwesomeButtonComponent } from '@shared/components/font-awesome-button/font-awesome-button.component';
import { CustomNumberKeyboardComponent } from '@shared/components/custom-number-keyboard/custom-number-keyboard.component';
import { HeaderNavMenuComponent } from '@shared/modules/layouts/components/header/header-nav-menu/header-nav-menu.component';
import { HorizontalLineWithTextComponent } from '@shared/components/horizontal-line-with-text/horizontal-line-with-text.component';
import { SynchronizationComponent } from '../features/mobile/mobile-dashboard/components/syncronization/synchronization.component';
import { FlightsComponent } from '@shared/components/flights/flights.component';
import { SalesOrdersComponent } from '@shared/components/sales-orders/sales-orders.component';
import { ForecastPackageTypeDataComponent } from '../features/admin/package-management/components/forecast-package-type-data/forecast-package-type-data.component';
import { DelayedShipmentInformationComponent } from '@shared/components/delayed-shipment-information/delayed-shipment-information.component';

// DIRECTIVES
import { AfterTodayDirective } from './directives/after-today.directive';
import { DoubleClickDirective } from './directives/double-click.directive';
import { MasonryBlockDirective } from '@shared/directives/masonry-block.directive';
import { IsBreakpointDirective } from '@shared/directives/is-breakpoint.directive';
import { ShowActiveTabContentDirective } from './directives/show-active-tab-content.directive';
import { SubMenuTabContentDirective } from '@shared/directives/sub-menu-tab-content.directive';

// PIPES
import { ToDatePipe } from './pipes/to-date.pipe';
import { JoinPipe } from '@shared/pipes/join.pipe';
import { E2eAttrPipe } from './pipes/e2e-attr.pipe';
import { ContainsPipe } from './pipes/contains.pipe';
import { FullNamePipe } from './pipes/full-name.pipe';
import { UniquePipe } from '@shared/pipes/unique.pipe';
import { SortByPipe } from '@shared/pipes/sort-by.pipe';
import { SubStringPipe } from './pipes/sub-string.pipe';
import { BoolifyPipe } from '@shared/pipes/boolify.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { SumByFieldPipe } from './pipes/sum-by-field.pipe';
import { SquareMetrePipe } from './pipes/square-metre.pipe';
import { GetActionPipe } from '@shared/pipes/get-action.pipe';
import { GetOptionPipe } from '@shared/pipes/get-option.pipe';
import { MapItemsByKeyPipe } from '@shared/pipes/map-items-key';
import { BaseDataValuePipe } from './pipes/base-data-value.pipe';
import { CanDeleteFarmPipe } from './pipes/can-delete-farm.pipe';
import { SubtractValuesPipe } from '@shared/pipes/calculate.pipe';
import { IsDataStatePipe } from '@shared/pipes/is-data-state.pipe';
import { CreateObjectUrlPipe } from './pipes/createObjectUrl.pipe';
import { GenerateActionsPipe } from './pipes/generate-actions.pipe';
import { GetOrganisationPipe } from './pipes/get-organisation.pipe';
import { DistinctOptionsPipe } from '@shared/pipes/distinct-options.pipe';
import { BaseDataOptionsPipe } from '@shared/pipes/base-data-options.pipe';
import { DebounceTimeByTypePipe } from './pipes/debounce-time-by-type.pipe';
import { IsValidFormControlPipe } from './pipes/is-valid-form-control.pipe';
import { DefaultDateFormattingPipe } from './pipes/default-date-formatting.pipe';
import { FlightPipe } from '@shared/pipes/flight.pipe';
import { ReplaceCommaSeparatorPipe } from './pipes/replace-comma-separator.pipe';
import { FixedOptionsPipe } from '@shared/pipes/fixed-options.pipe';
import { IsLockedComponent } from '../features/admin/auction-sales/components/is-locked/is-locked.component';
import { DateFilterSelectionModePipe } from '@shared/pipes/date-filter-selection-mode.pipe';

const BASE_PIPES = [
  BoolifyPipe,
  BaseDataValuePipe,
  SquareMetrePipe,
  DefaultPipe,
  FullNamePipe,
  E2eAttrPipe,
  IsDataStatePipe,
  ToDatePipe,
  DefaultDateFormattingPipe,
  JoinPipe,
  SortByPipe,
  ContainsPipe,
  MapItemsByKeyPipe,
  BaseDataOptionsPipe,
  GetOptionPipe,
  DebounceTimeByTypePipe,
  SubStringPipe,
  SubtractValuesPipe,
  UniquePipe,
  SumByFieldPipe,
  GetOrganisationPipe,
  CanDeleteFarmPipe,
  IsValidFormControlPipe,
  CreateObjectUrlPipe,
  DistinctOptionsPipe,
  GenerateActionsPipe,
  GetActionPipe,
  FlightPipe,
  ReplaceCommaSeparatorPipe,
  FixedOptionsPipe,
  DateFilterSelectionModePipe,
];

const BASE_COMPONENTS = [
  BadgeButtonComponent,
  SecondaryButtonComponent,
  UploaderComponent,
  FormlyRepeatTypeComponent,
  HeaderNavMenuComponent,
  FilePreviewComponent,
  FilePreviewCardComponent,
  OverviewCardComponent,
  FormDialogComponent,
  HorizontalLineWithTextComponent,
  DetailsScreenComponent,
  HistoryModalComponent,
  TimelineModelLogComponent,
  DetailCardComponent,
  CustomNumberKeyboardComponent,
  OriginCardComponent,
  ActionMenuComponent,
  SynchronizationComponent,
  MasonryComponent,
  CardBodyRowComponent,
  AccordionListComponent,
  AlertComponent,
  UploadModalComponent,
  CsvUploadResultComponent,
  FilterButtonsComponent,
  ResetFilterComponent,
  SelectListItemComponent,
  TimePickerComponent,
  UserMenuComponent,
  SwitchFarmListComponent,
  NavigationButtonComponent,
  FontAwesomeButtonComponent,
  DatePickerButtonComponent,
  AmountInputComponent,
  SkeletonTableComponent,
  TileListComponent,
  FlightsComponent,
  SalesOrdersComponent,
  ForecastPackageTypeDataComponent,
  DelayedShipmentInformationComponent,
  IsLockedComponent,
];

const BASE_DIRECTIVES = [
  SubMenuTabContentDirective,
  AfterTodayDirective,
  ShowActiveTabContentDirective,
  MasonryBlockDirective,
  DoubleClickDirective,
  IsBreakpointDirective,
];

const BASE_IMPORT_EXPORT = [
  CapturumButtonModule,
  CapturumDropdownModule,
  CapturumMultiSelectModule,
  CapturumInputModule,
  CapturumCheckboxModule,
  CapturumCalendarModule,
  CapturumTextareaModule,
  CapturumLayoutModule,
  CapturumTableModule,
  DialogModule,
  SplitButtonModule,
  ProgressSpinnerModule,
  TabViewModule,
  CompleteModule,
  TranslateModule,
  NgxPermissionsModule,
];

@NgModule({
  declarations: [...BASE_COMPONENTS, ...BASE_DIRECTIVES, ...BASE_PIPES],
  exports: [
    ...BASE_COMPONENTS,
    ...BASE_DIRECTIVES,
    ...BASE_PIPES,
    ...BASE_IMPORT_EXPORT,
    FormlyModule,
    DynamicDialogModule,
  ],
  imports: [
    ...BASE_IMPORT_EXPORT,
    CommonModule,
    CapturumFileUploadModule,
    FormlyModule.forRoot({
      types: [{ name: 'repeat', component: FormlyRepeatTypeComponent }],
    }),
    ReactiveFormsModule,
    CapturumTimelineModule,
    DynamicAccordionModule,
    NgxPermissionsModule,
    RadioButtonModule,
    CapturumInputMaskModule,
    InfoTableModule,
    CapturumRadioModule,
    FormsModule,
    CapturumSkeletonModule,
    OverlayPanelModule,
    RouterModule,
    CapturumListRendererModule,
  ],
})
export class SharedModule {}
