<div class="user-menu">
  <ul class="user-menu__items">
    @for (menu of profileMenuItems$ | async; track menu) {
      <li *ngxPermissionsOnly="menu?.permission" (click)="executeCommand(menu)">
        <i [className]="menu?.icon"></i>
        <span>{{ menu?.label | translate }}</span>
      </li>
    }
  </ul>

  <div class="d-none d-lg-block description">{{ 'dxp.farm-switch.description' | translate }}</div>

  <div class="d-none d-lg-block user-menu__farms">
    <app-switch-farm-list></app-switch-farm-list>
  </div>
</div>
