import { Injectable } from '@angular/core';
import { MobileNavConfig } from '@core/models/mobile-nav-config.model';
import { Observable, Subject } from 'rxjs';
import { MobileAction } from '@core/enums/mobile-action.enum';

@Injectable({
  providedIn: 'root',
})
export class MobileNavService {
  public configs: MobileNavConfig[] = [];
  public configAction: Subject<MobileAction> = new Subject();
  public configAction$: Observable<MobileAction> = this.configAction.asObservable();

  public addConfig(config: MobileNavConfig): void {
    const index = this.configs.findIndex((item) => {
      return item.routerUrl === config.routerUrl;
    });

    if (index === -1) {
      this.configs = [...this.configs, config];
    } else {
      this.configs[index] = config;
    }

    this.configAction.next(MobileAction.add);
  }

  public getConfig(url: string): MobileNavConfig {
    return this.configs.find((config) => {
      return config.routerUrl === url;
    });
  }
}
