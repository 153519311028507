import { inject, Injectable } from '@angular/core';
import { AuthService } from '@capturum/auth';
import { switchMap, take } from 'rxjs/operators';
import { IndexedDbService } from '@capturum/complete';
import { DxpIndexablePermissionService } from '@core/indexDb/services/dxp-indexable-permission.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { SetActiveFarm } from '@store/admin/general/general.actions';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  protected dxpIndexablePermissionService = inject(DxpIndexablePermissionService);

  constructor(
    private readonly ngxPermissionService: NgxPermissionsService,
    private readonly indexedDbService: IndexedDbService,
    private readonly translateService: TranslateService,
    private readonly store: Store,
    private readonly authService: AuthService,
  ) {}

  public forceLogout(): void {
    this.dxpIndexablePermissionService
      .resetPermissions()
      .pipe(
        take(1),
        switchMap(() => {
          return this.authService.logout();
        }),
      )
      .subscribe(() => {
        // Client doesn't want to load lastRoute when logging back in
        localStorage.removeItem('lastRoute');
        // Unset active farm
        this.store.dispatch(new SetActiveFarm(null));
        // Because we've removed the permissions, reloading will trigger a re-route to 'auth/login' and for
        // some reason the new user will see some (cached?) old permissions
        location.reload();
      });
  }

  public clearCache(reloadPage = true): void {
    // Clear storage
    localStorage.clear();
    sessionStorage.clear();

    // Clear Permissions
    this.ngxPermissionService.flushPermissions();

    // Clear languages
    this.translateService.getLangs().forEach((language) => {
      this.translateService.resetLang(language);
    });

    // Clear indexDb tables
    this.indexedDbService.resetStore().then(() => {
      // Refresh and get redirect to login page
      if (reloadPage) {
        location.reload();
      }
    });
  }
}
