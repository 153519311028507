import moment, { unitOfTime } from 'moment';
import { DatesFormats } from '@core/configs/date-config';
import { SortDirection } from '@core/enums/ui-general.enum';
import { SafariHelper } from '@core/utils/safari.helper';

export class DateHelper {
  public static toDateObject(date: string | Date): Date {
    return moment(date).toDate();
  }

  public static toDateFormat(date: string | Date): string {
    const convertedDate = SafariHelper.checkIsSafariBrowser(date);

    return moment(convertedDate as Date).format(DatesFormats.dayFormat);
  }

  public static toFormattedString(date: string | Date, format: string = DatesFormats.dayMonthYearTime): string {
    return moment(date).format(format);
  }

  public static toGeneralFormat(date: Date, timezoneManipulation = true): string {
    const adjustedDate = moment(date).format(DatesFormats.sendFormat);

    if (timezoneManipulation) {
      return moment(DateHelper.timezoneManipulation(new Date(adjustedDate))).format(DatesFormats.sendFormat);
    }

    return moment(new Date(adjustedDate)).format(DatesFormats.sendFormat);
  }

  public static getStartOfNextWeek(): Date {
    return moment(new Date()).add(1, 'week').startOf('isoWeek').toDate();
  }

  public static toFilterRangeFormat(dates: Date[], timezoneManipulation = true): string[] {
    if (dates && Array.isArray(dates)) {
      if (dates.length === 2 && !!dates[1]) {
        return DateHelper.getDateRange(dates, timezoneManipulation);
      } else if ((dates.length === 2 && !dates[1]) || dates.length === 1) {
        return DateHelper.getDateRange([dates[0], dates[0]], timezoneManipulation);
      }
    }

    const today = new Date();

    return [DateHelper.getStartOfDay(today), DateHelper.getEndOfDay(today)];
  }

  public static fromStringToDate(date: string | string[]): Date | Date[] {
    if (date && Array.isArray(date)) {
      return date.map((item) => {
        if (item) {
          return new Date(item);
        }

        return item as any;
      });
    } else if (typeof date === 'string') {
      return new Date(date);
    }
  }

  public static toTimestamp(date: Date): number {
    return Math.trunc(date.getTime() / 1000);
  }

  public static isBiggerThenToday(date: string | Date): boolean {
    return moment(date).toDate() > moment(new Date()).toDate();
  }

  public static getFormattedTodayAndTomorrow(dateFormat: DatesFormats): string[] {
    const today = new Date();
    const tomorrow = DateHelper.getTomorrow(today);

    return [moment(today).format(dateFormat), moment(tomorrow).format(dateFormat)];
  }

  public static getToday(): string {
    return moment(new Date()).format(DatesFormats.dayFormat);
  }

  public static getTodayAsDateObj(): Date {
    return moment(DateHelper.getToday()).toDate();
  }

  public static getTodayAndTomorrow(): Date[] {
    const today = new Date();
    const tomorrow = DateHelper.getTomorrow(today);

    return [today, tomorrow];
  }

  public static getCurrentTimestamp(format?: DatesFormats): string {
    return moment().format(format ? format : DatesFormats.displayFormat);
  }

  public static getStartOfCurrentWeekAndEndOfFutureWeek(week = 1): Date[] {
    const startOfCurrentWeek = moment().startOf('isoWeek').toDate();
    const endOfFutureWeek = moment().endOf('isoWeek').add(week, 'week').toDate();

    return [startOfCurrentWeek, endOfFutureWeek];
  }

  public static offsetDay(date: string | Date, offset: number): string {
    return moment(date).add(offset, 'days').format(DatesFormats.dayFormat);
  }

  public static getTodayWithProvidedTime(time: string): Date | string {
    const hourAndMin = time.split(':');
    const today = new Date();

    if (+hourAndMin[0] >= 25 || +hourAndMin[1] >= 61) {
      return time;
    }

    return new Date(today.setHours(+hourAndMin[0], +hourAndMin[1]));
  }

  public static extractTime(value?: string | Date): string {
    const date = value ? new Date(value) : new Date();
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

    return `${hours}:${minutes}`;
  }

  public static getDateRange(dates: Date[], timezoneManipulation = true): string[] {
    return [
      DateHelper.getStartOfDay(dates[0], DatesFormats.sendFormat, timezoneManipulation),
      DateHelper.getEndOfDay(dates[1], DatesFormats.sendFormat, timezoneManipulation),
    ];
  }

  public static getStartOfDay(
    date?: string | Date | null,
    format: string = DatesFormats.sendFormat,
    timezoneManipulation = true,
  ): string {
    let newDate = date;

    if (!newDate) {
      newDate = new Date();
    }

    const adjustedDate = moment(newDate).startOf('day').format(format);

    return DateHelper.toGeneralFormat(new Date(adjustedDate), timezoneManipulation);
  }

  public static getEndOfDay(
    date: string | Date,
    format: string = DatesFormats.sendFormat,
    timezoneManipulation = true,
  ): string {
    const adjustedDate = moment(date).endOf('day').format(format);

    return DateHelper.toGeneralFormat(new Date(adjustedDate), timezoneManipulation);
  }

  public static getTomorrow(currentDate: Date = new Date()): Date {
    const tomorrow = new Date(currentDate);

    tomorrow.setDate(currentDate.getDate() + 1);

    return tomorrow;
  }

  public static sortBy(data: any[], property = 'created_at', direction: SortDirection = SortDirection.asc): any[] {
    return data.sort((a, b) => {
      if (direction === SortDirection.asc) {
        return new Date(a[property]).getTime() > new Date(b[property]).getTime() ? 1 : -1;
      } else {
        return new Date(a[property]).getTime() < new Date(b[property]).getTime() ? 1 : -1;
      }
    });
  }

  public static getStartOfWeekAndEndOfNextWeek(week = 0, weekOffset = 1): string[] {
    const startDate = DateHelper.startOfDay();
    const endDate = DateHelper.endOfDay();
    const startWeek = moment(startDate).startOf('isoWeek').add(week, 'week').format(DatesFormats.sendFormat);
    const endWeek = moment(endDate)
      .endOf('isoWeek')
      .add(week + weekOffset, 'week')
      .format(DatesFormats.sendFormat);

    return [startWeek, endWeek];
  }

  public static getStartOfCurrentWeekAndEndOfCurrentWeek(week = 0): string[] {
    const startDate = DateHelper.startOfDay();
    const endDate = DateHelper.endOfDay();
    const startWeek = moment(startDate).startOf('isoWeek').add(week, 'week').format(DatesFormats.sendFormat);
    const endWeek = moment(endDate).endOf('isoWeek').add(week, 'week').format(DatesFormats.sendFormat);

    return [startWeek, endWeek];
  }

  public static getWeekNumber(date: string): number {
    return moment(date, DatesFormats.sendFormat).isoWeek();
  }

  public static calculateWeeksBetweenDates(date1: string | Date, date2: string | Date): number {
    const start = moment(new Date(date1));
    const end = moment(new Date(date2));
    const weeks = +moment.duration(start.diff(end)).asWeeks().toFixed(0);

    return weeks - weeks * 2;
  }

  public static timezoneManipulation(date: Date): Date {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;

    date.setTime(date.getTime() - timeOffsetInMS);

    return date;
  }

  public static startOfDay(): number {
    return new Date().setHours(0, 0, 0);
  }

  public static endOfDay(): number {
    return new Date().setHours(23, 59, 59);
  }

  public static getMonthStartAndEndDate(date: Date = new Date()): Date[] {
    const firstDay = new Date(date?.getFullYear(), date?.getMonth(), 1);
    const lastDay = new Date(date?.getFullYear(), date?.getMonth() + 1, 0);

    return [firstDay, lastDay];
  }

  public static getDayAgo(days: number, date: Date = new Date()): Date {
    date.setDate(date.getDate() - days);

    return date;
  }

  public static getDayForward(days: number): Date {
    const date = new Date();

    date.setDate(date.getDate() + days);

    return date;
  }

  public static getDayAgoAndToday(days: number): Date[] {
    const today = new Date();
    const dayAgo = DateHelper.getDayAgo(days);

    return [dayAgo, today];
  }

  public static getDayAgoAndDayForward(daysAgo: number, daysForward: number): Date[] {
    const dayAgo = DateHelper.getDayAgo(daysAgo);
    const dayForward = DateHelper.getDayForward(daysForward);

    return [dayAgo, dayForward];
  }

  public static getDayAfter(days: number, dateFrom: Date = new Date()): Date {
    return moment(dateFrom).add(days, 'days').toDate();
  }

  public static getDatesDifference(dateFrom: Date, dateTo: Date, unit: unitOfTime.Base = 'days'): number {
    return moment(dateFrom).diff(moment(dateTo), unit);
  }

  public static getFormattedDateRange(dates: Date[], format: string = DatesFormats.sendFormat): string[] {
    return [moment(dates[0]).startOf('day').format(format), moment(dates[1]).endOf('day').format(format)];
  }
}
