<div class="harvest card h-100 {{ styleClass }}">
  <div class="card-header pb-0">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <hr />
  </div>

  <div class="card-body py-0">
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
  </div>

  <div class="card-footer pt-2 pb-3">
    <ng-container *ngTemplateOutlet="actionTemplate || defaultActionTemplate"></ng-container>

    <ng-template #defaultActionTemplate>
      @if (showAction) {
        <div class="action-wrapper w-100 mt-3">
          <cap-button
            styleClass="primary primary-outline"
            [label]="actionLabel"
            [icon]="actionIcon"
            (onClick)="triggerAction()">
          </cap-button>
        </div>
      }
    </ng-template>
  </div>
</div>
