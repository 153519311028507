import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  public getScreenSize(): Observable<ScreenSize> {
    return this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(
        map(({ matches, breakpoints }) => {
          const matchingBreakpoint = Object.keys(breakpoints).find((key) => {
            return breakpoints[key] === true;
          });

          if (Breakpoints.Small.includes(matchingBreakpoint) || Breakpoints.Medium.includes(matchingBreakpoint)) {
            return ScreenSize.tablet;
          } else if (Breakpoints.XSmall.includes(matchingBreakpoint)) {
            return ScreenSize.mobile;
          }

          return ScreenSize.desktop;
        }),
      );
  }
}
