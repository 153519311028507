import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiHttpService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import {
  CompleteConfig,
  IndexedDbGuard,
  IndexedDbService,
  Permission,
  PermissionIndexedDbModel,
} from '@capturum/complete';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SetActiveFarm } from '@store/admin/general/general.actions';
import { IndexableDataService } from '@core/indexDb/services/dxp-indexable-data.service';
import { IndexableServiceHelper } from '@core/indexDb/utils/indexable-service.helper';
import { AuthService } from '@capturum/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store as NGXS } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class DxpIndexablePermissionService extends IndexableDataService<Permission> {
  protected endpoint = 'role/permission';
  protected outdatedEntityKey = 'permissions';

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
    protected readonly ngxPermissionService: NgxPermissionsService,
    protected readonly ngxsStore: NGXS,
  ) {
    super(api, translateService, authService, completeConfig, indexedDbService, indexedDbGuard);
  }

  /**
   * I would deprecate this method, but @Emendis/complete components rely on it
   */
  public loadPermissions(forceReload = false): Observable<any> {
    return this.getData(forceReload);
  }

  /**
   * Don't forget to flush permissions
   */
  public clearData(): Observable<void> {
    return super.clearData().pipe(
      finalize(() => {
        return this.ngxPermissionService.flushPermissions();
      }),
      takeUntil(this.destroy$),
    );
  }

  public onFatalError(): Observable<void> {
    return super.onFatalError().pipe(
      switchMap(() => {
        return this.resetPermissions();
      }),
      switchMap(() => {
        return this.authService.logout();
      }),
      map(() => {
        return null;
      }),
      tap(() => {
        // Client doesn't want to load lastRoute when logging back in
        localStorage.removeItem('lastRoute');
        this.ngxsStore.dispatch(new SetActiveFarm(null));
        // Because we've removed the permissions, reloading will trigger a re-route to 'auth/login' and for
        // some reason the new user will see some (cached?) old permissions
        location.reload();
      }),
    );
  }

  public resetPermissions(): Observable<boolean> {
    // Clear permissions, so the next user won't inherit permissions from the old use
    return this.clearData().pipe(
      map(() => {
        IndexableServiceHelper.updateOutdatedDataInStorage('permissions', true);

        return true;
      }),
    );
  }

  /**
   * Retrieve all permissions of the current tenant (@Emendis/Complete needs this method)
   */
  public getPermissionsOfCurrentTenant(): Observable<Permission[]> {
    return this.api.get('/tenant/permission').pipe(
      map((response: { data: Permission[] }) => {
        return response?.data;
      }),
    );
  }

  public onTransformRequestedData(permissions: string[]): Observable<any> {
    const transformedPermissions = Object.values(permissions).map((value, index) => {
      return { id: index, name: value };
    });

    return of(transformedPermissions);
  }

  public onIndexedDataFinished(data: PermissionIndexedDbModel[]): Observable<any> {
    const transformedPermissions = Object.values(data).map((permission) => {
      return permission?.name;
    });

    this.reIndexPermissions(transformedPermissions);

    return of(data);
  }

  public reIndexPermissions(data: string[]): void {
    this.ngxPermissionService.flushPermissions();
    this.ngxPermissionService.loadPermissions(data);
  }
}
