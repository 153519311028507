import { Action, State, StateContext } from '@ngxs/store';
import { SyncStateModel } from './sync-state.model';
import { ResetSync, SetCurrentError, SetProgressStatus, SetStoredAmount, SetSyncErrors } from './sync.actions';
import { Injectable } from '@angular/core';

@State<SyncStateModel>({
  name: 'sync',
  defaults: {
    amount: 0,
    syncErrors: [],
    inProgress: false,
  },
})
@Injectable()
export class SyncState {
  @Action(SetStoredAmount)
  public set(ctx: StateContext<SyncStateModel>, { amount }: SetStoredAmount): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      amount,
    });
  }

  @Action(SetProgressStatus)
  public progress(ctx: StateContext<SyncStateModel>, { status }: SetProgressStatus): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      inProgress: status,
    });
  }

  @Action(ResetSync)
  public reset(ctx: StateContext<SyncStateModel>): void {
    ctx.setState({ amount: 0, syncErrors: [] });
  }

  @Action(SetSyncErrors)
  public setErrors(ctx: StateContext<SyncStateModel>, { syncErrors }: SetSyncErrors): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      syncErrors,
    });
  }

  @Action(SetCurrentError)
  public setCurrentError(ctx: StateContext<SyncStateModel>, { syncError }: SetCurrentError): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      currentSyncError: syncError,
    });
  }
}
