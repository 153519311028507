import { Component, Input } from '@angular/core';
import { ConfigItem } from '@capturum/complete';

@Component({
  selector: 'app-tile-list',
  templateUrl: './tile-list.component.html',
  styleUrls: ['./tile-list.component.scss'],
})
export class TileListComponent {
  @Input() public title: string;
  @Input() public items: ConfigItem[];

  public onClick(item: ConfigItem): void {
    if (item.hasOwnProperty('callback')) {
      item.callback();
    }
  }
}
