import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subtractValues',
})
export class SubtractValuesPipe implements PipeTransform {
  public transform(input: (number | string)[]): number {
    return input.reduce((acc, value) => {
      return Number(acc) - Number(value);
    }) as number;
  }
}
