<a
  class="button-badge"
  [ngClass]="{ 'button-badge-light': isLightTheme }"
  [ngStyle]="{ width: proportions + 'px', height: proportions + 'px' }"
  (click)="clicked.next($event)">
  <div class="badge-wrapper">
    <i [class]="icon" [ngClass]="{ 'g-rotation': rotate }"></i>
    @if (warning) {
      <i class="warning-badge fas fa-exclamation"></i>
    } @else {
      @if (badgeValue) {
        <span class="badge rounded-circle">{{ badgeValue }}</span>
      }
    }
  </div>
</a>
