<div class="accordion-list__header">
  <div class="title">
    <h2>{{ title }}</h2>
  </div>

  <div class="icon" (click)="addClick.emit()" *ngxPermissionsOnly="addPermissions">
    <i class="fa fa-plus" aria-hidden="true"></i>
  </div>
</div>

<ng-content></ng-content>
