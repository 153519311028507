<div class="synchronization">
  @if ((isOnline$ | async) && !(existsSyncErrors$ | async | boolify) && (storedItems$ | async); as storedItems) {
    <app-badge-button
      icon="fas fa-sync-alt"
      [badgeValue]="storedItems"
      [rotate]="inProgress"
      (clicked)="!inProgress ? syncClicked?.emit($event) : null">
    </app-badge-button>
  }

  @if ((isOnline$ | async) === false && (storedItems$ | async); as storedItems) {
    <span class="mr-2">
      @if (storedItems) {
        <app-badge-button icon="fas fa-archive" [badgeValue]="storedItems"> </app-badge-button>
      }
    </span>
  }

  @if (existsSyncErrors$ | async) {
    <app-badge-button
      icon="fas fa-sync-alt"
      [routerLink]="['/', AppRoutes.mobile, AppRoutes.syncErrors]"
      [warning]="true">
    </app-badge-button>
  }
</div>
