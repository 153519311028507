import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { DestroyBase } from '@core/base/destroy.class';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appSubMenuTabContent]',
})
export class SubMenuTabContentDirective extends DestroyBase implements OnInit {
  @Input() public navTabIndex: number;

  constructor(
    private container: ViewContainerRef,
    private layoutUtilsService: LayoutUtilsService,
  ) {
    super();

    layoutUtilsService.subMenuTabSelected$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (layoutUtilsService.isNavTabBarShown && this.navTabIndex >= 0 && index >= 0) {
          if (this.navTabIndex === index) {
            this.showComponentElement();
          } else {
            this.hideComponentElement();
          }
        }
      });
  }

  public ngOnInit(): void {
    if (
      this.layoutUtilsService.isNavTabBarShown &&
      this.navTabIndex !== this.layoutUtilsService.getCurrentTabSelected()
    ) {
      this.hideComponentElement();
    }
  }

  public showComponentElement(): void {
    this.container.element.nativeElement.style.display = 'block';
  }

  public hideComponentElement(): void {
    this.container.element.nativeElement.style.display = 'none';
  }
}
