import { EntityAction } from '@core/enums/entity-action.enum';
import { Entity } from '@core/enums/entity.enum';
import { Injectable } from '@angular/core';
import { NotificationCta, NotificationMessage, NotificationPosition } from './base/notification.model';
import { NotificationType } from './base/notification-type.enum';
import { NotificationState } from './notification.state';
import { ToastProvider } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

@Injectable()
export class NotificationService implements ToastProvider {
  private defaultTimeouts = {
    success: 3000,
    warning: 3000,
    error: 9999999,
    info: 3000,
    version: 9999999,
    cta: 9999999,
  };

  private allowDuplicateNotification = {
    success: true,
    warning: true,
    error: false,
    info: true,
    version: true,
    cta: false,
  };

  constructor(
    private notificationState: NotificationState,
    private translationService: TranslateService,
  ) {}

  public getTimeout(field: NotificationType): number {
    return this.defaultTimeouts[field];
  }

  public entityActionSuccess(entity: Entity | string, action: EntityAction, dxpPrefix?: boolean): void {
    const title = this.translationService.instant('toast.success.title');
    const messageKey = `${dxpPrefix ? 'dxp.' : ''}toast.success.${action}`;
    const message = this.translationService.instant(messageKey, { entity });

    this.success(title, message);
  }

  public success(title: string, message: string, position?: NotificationPosition): void {
    const notification = {
      id: uuid(),
      title,
      message,
      type: NotificationType.success,
      position: { top: '90%' },
      timeout: this.defaultTimeouts.success,
      allowDuplicate: this.allowDuplicateNotification.success,
    };

    this.append(notification);
  }

  public warning(title: string, message: string, position?: NotificationPosition): void {
    const notification = {
      id: uuid(),
      title,
      message,
      type: NotificationType.warning,
      position: { top: '90%' },
      timeout: this.defaultTimeouts.warning,
      allowDuplicate: this.allowDuplicateNotification.warning,
    };

    this.append(notification);
  }

  public error(title: string, message: string, position?: NotificationPosition): void {
    const notification = {
      id: uuid(),
      title,
      message,
      type: NotificationType.error,
      position: { top: '90%' },
      timeout: this.defaultTimeouts.error,
      allowDuplicate: this.allowDuplicateNotification.error,
    };

    this.append(notification);
  }

  public info(title: string, message: string, position?: NotificationPosition, timeout?: number): void {
    const notification = {
      id: uuid(),
      title,
      message,
      type: NotificationType.info,
      position: { top: '90%' },
      timeout: timeout || this.defaultTimeouts.info,
      allowDuplicate: this.allowDuplicateNotification.info,
    };

    this.append(notification);
  }

  public version(
    title: string,
    message: string,
    position?: NotificationPosition,
    timeout?: number,
    cta?: NotificationCta,
  ): void {
    const notification = {
      id: uuid(),
      title,
      message,
      type: NotificationType.version,
      position: { top: '90%' },
      timeout: timeout || this.defaultTimeouts.version,
      allowDuplicate: this.allowDuplicateNotification.version,
      cta,
    };

    this.append(notification);
  }

  public cta(
    title: string,
    message: string,
    position?: NotificationPosition,
    timeout?: number,
    cta?: NotificationCta,
  ): NotificationMessage {
    const notification = {
      id: uuid(),
      title,
      message,
      type: NotificationType.cta,
      position: { top: '90%' },
      timeout: timeout || this.defaultTimeouts.cta,
      showTimeoutBar: !!timeout || false,
      allowDuplicate: this.allowDuplicateNotification.cta,
      cta,
    };

    this.append(notification);

    return notification;
  }

  public displayGenericToastError(): void {
    this.error(
      this.translationService.instant('toast.error.title'),
      this.translationService.instant('toast.error.message'),
    );
  }

  private append(notification: NotificationMessage): void {
    this.notificationState.notification.next(notification);
  }
}
