import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DestroyBase } from '@core/base/destroy.class';
import { BreakpointService } from '@core/services/breakpoint.service';

@Directive({
  selector: '[appIsBreakpoint]',
})
export class IsBreakpointDirective extends DestroyBase implements OnInit {
  @Input('appIsBreakpoint') public screenSize: keyof typeof ScreenSize;

  private screenSizeRange: Record<ScreenSize, ScreenSize[]> = {
    [ScreenSize.mobile]: [ScreenSize.mobile],
    [ScreenSize.tablet]: [ScreenSize.tablet],
    [ScreenSize.desktop]: [ScreenSize.desktop],
  };

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly breakpointService: BreakpointService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.breakpointService
      .getScreenSize()
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((screenSize) => {
        this.viewContainer.clear();
        if (this.screenSizeRange[this.screenSize].includes(screenSize)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }
}
