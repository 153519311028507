import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-preview-card',
  templateUrl: './file-preview-card.component.html',
  styleUrls: ['./file-preview-card.component.scss'],
})
export class FilePreviewCardComponent {
  @Input() public items: UntypedFormArray;
  @Input() public file: any;
  @Input() public deletable: boolean;
  @Input() public showMode: boolean;
  @Input() public haveType: boolean;
  @Input() public thumbnail: string;
  @Input() public styleClass = 'col-6 col-xl-4';
  @Output() public removeClick: EventEmitter<string> = new EventEmitter();
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();

  public mockFormGroup: UntypedFormGroup = new UntypedFormGroup({});

  public handleRemoveIconClick(event: Event, filename: string): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.removeClick.next(filename);
  }
}
