@if (origin | async; as origin) {
  <div class="d-flex">
    <div class="action__circle">
      <i class="fas fa-map-marker-alt"></i>
    </div>
    <div class="action__content">
      <h3>{{ 'dxp.mobile-app.origin.title' | translate }}</h3>
    </div>
  </div>
  <div class="action__filled">
    <div class="action--scanned">
      @if (origin?.station) {
        <p class="bold primary">
          {{ 'dxp.mobile-app.origin.station.label' | translate }}
          {{ origin?.station?.label }}
        </p>
      }
      <p class="semi-bold">
        {{ 'dxp.mobile-app.origin.block.label' | translate }}
        {{ origin?.block?.label }}
      </p>
      @if (origin?.session_created) {
        <p class="semi-bold">
          {{ 'dxp.mobile-app.origin.session_start.label' | translate }}
          {{ origin?.session_created | defaultDateFormatting }}
        </p>
      }
      @if (origin?.product) {
        <p>
          <span>
            {{ origin?.product?.label | uppercase }}
          </span>
        </p>
      }
    </div>
  </div>
}
