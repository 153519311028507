import { Pipe, PipeTransform } from '@angular/core';
import { FilterMatchMode } from '@capturum/ui/api';

@Pipe({
  name: 'dateFilterSelectionModePipe',
})
export class DateFilterSelectionModePipe implements PipeTransform {
  public transform(matchMode: FilterMatchMode): string {
    if (matchMode === FilterMatchMode.BETWEEN || matchMode === FilterMatchMode.DATEBETWEEN) {
      return 'range';
    }

    return 'single';
  }
}
