import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges, TemplateRef } from '@angular/core';
import { ModelLog } from '@core/models/model-log.model';
import { ModelLoggableType } from '@core/enums/model-loggable-type.enum';
import { HistoryItem } from '@core/models/history-item.model';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { KeyValue } from '@angular/common';
import { isEmpty } from 'lodash';
import { EntityUtil } from '@core/utils/entity-util';

@Component({
  selector: 'app-timeline-model-log',
  templateUrl: './timeline-model-log.component.html',
  styleUrls: ['./timeline-model-log.component.scss'],
  providers: [DialogService],
})
export class TimelineModelLogComponent implements OnInit, OnChanges {
  @Input() public items: { [key: string]: ModelLog[] };
  @Input() public isCollapsable: boolean;
  @Input() public planningEntities: boolean;
  @Input() public entityTranslationKey: string;
  @Input() public useShowFullHistoryFromConfig = true;
  @Input() public showFullHistory = false;
  @Input() public leftTitle = 'dxp.general.model-log.title';
  @Input() public headerTemplate: TemplateRef<any>;
  public historyItems: { [p: string]: HistoryItem };
  public isEmptyHistoryItems = false;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    @Optional() public config: DynamicDialogConfig,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items.previousValue !== changes.items.currentValue) {
      this.historyItems = this.transformItems(changes.items.currentValue);
    }
  }

  public ngOnInit(): void {
    if (this.config) {
      // historyItems = items because we already have historyItems don't need processing with transformItems()
      this.historyItems = this.config.data?.items;
      this.isCollapsable = this.config.data?.isCollapsable;
      this.planningEntities = this.config.data?.planningEntities;
      this.entityTranslationKey = this.config.data?.entityTranslationKey;

      if (this.useShowFullHistoryFromConfig) {
        this.showFullHistory = this.config.data?.showFullHistory;
      }
    }

    this.isEmptyHistoryItems = isEmpty(this.historyItems);
  }

  public openDialog(): void {
    if (!this.items && this.showFullHistory) {
      return;
    }

    this.dialogService.open(TimelineModelLogComponent, {
      header: this.translateService.instant('dxp.history.full-history'),
      styleClass: 'history-timeline-dialog',
      data: {
        items: this.historyItems,
        showFullHistory: true,
        isCollapsable: this.isCollapsable,
        planningEntities: this.planningEntities,
        entityTranslationKey: this.entityTranslationKey,
      },
    });
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  private transformItems(items: { [key: string]: ModelLog[] }): { [key: string]: HistoryItem } {
    const historyItems = {};

    if (items) {
      for (const dateTime of Object.keys(items)) {
        let hasDiff = false;

        const modelLogs = items[dateTime]
          ?.map((item) => {
            item.entity_key = EntityUtil.getEntityKey(item.model_loggable_type);

            if (!item.action) {
              const newModel = JSON.parse(item.new_model || '{}');

              item.action = item.original_model ? 'updated' : 'created';

              if (newModel?.parent_id) {
                item.action = 'splitted';
              }
            }

            if (Object.keys(item.diffs).length) {
              hasDiff = true;
            }

            return item;
          })
          .filter((item) => {
            // Filter out new grade splits with percentage '0'
            if (!item.original_model && item.entity_key === ModelLoggableType.HarvestPlanningGradeSplit) {
              const newModel = JSON.parse(item.new_model || '{}');

              return +newModel?.percentage !== 0;
            }

            return true;
          });

        historyItems[dateTime] = {
          showDetails: false,
          hasDiff,
          modelLogs,
        } as HistoryItem;
      }
    }

    return historyItems;
  }
}
