<cap-file-upload
  styleClass="g-uploader"
  [id]="'fileupload'"
  [multiple]="false"
  [uploaderContent]="uploadContent"
  [accept]="'.csv'"
  (onFileChange)="onFileChange($event[0])">
</cap-file-upload>

<div class="error-list mt-3">
  @if (response) {
    <app-csv-upload-result [result]="response"></app-csv-upload-result>
  }
</div>

<ng-template #uploadContent>
  <div class="upload">
    <i class="fas fa-cloud-upload-alt"></i>
    <p>{{ 'file.upload.description' | translate }}</p>
  </div>
</ng-template>
