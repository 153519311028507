import { Component, Input } from '@angular/core';
import { DialogAccordionItemBody } from '@shared/modules/dynamic-accordion/models/dynamic-accordion-item';

@Component({
  selector: 'app-dynamic-accordion-body',
  templateUrl: './dynamic-accordion-body.component.html',
})
export class DynamicAccordionBodyComponent {
  @Input()
  public content: DialogAccordionItemBody[];
}
