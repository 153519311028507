import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { DestroyBase } from '@core/base/destroy.class';
import { takeUntil } from 'rxjs/operators';
import { LayoutConfigService } from '@shared/services/layout-config.service';
import { ResponsiveService } from '@shared/services/responsive.service';
import { ScreenType } from '@core/enums/screen-type.enum';

@Directive({
  selector: '[appShowActiveTabContent]',
})
export class ShowActiveTabContentDirective extends DestroyBase implements OnInit {
  @Input() public navTabIndex: number;

  constructor(
    private container: ViewContainerRef,
    private layoutConfig: LayoutConfigService,
    private responsiveService: ResponsiveService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.layoutConfig.tabIndexChanged$.pipe(takeUntil(this.destroy$)).subscribe((newIndex) => {
      this.showTab(newIndex);
    });

    this.responsiveService.screenTypeChanged$.pipe(takeUntil(this.destroy$)).subscribe((newScreenType) => {
      this.reInit(newScreenType);
    });
  }

  public reInit(newScreenType: ScreenType): void {
    if (newScreenType === ScreenType.XS) {
      this.showTab(0);
    } else {
      this.showComponentElement();
    }
  }

  public showComponentElement(): void {
    this.container.element.nativeElement.style.display = 'block';
  }

  public hideComponentElement(): void {
    this.container.element.nativeElement.style.display = 'none';
  }

  private showTab(activeIndex: number): void {
    if (this.navTabIndex === activeIndex) {
      this.showComponentElement();
    } else {
      this.hideComponentElement();
    }
  }
}
