<cap-file-upload
  styleClass="g-uploader"
  [id]="getControlName()"
  [multiple]="multiple"
  label=""
  [uploaderContent]="uploadContent"
  [accept]="fileExtensions"
  (onFileChange)="setFiles($event)">
</cap-file-upload>

<!-- PREVIEW DOWNLOADED FILES SECTION -->
<div class="preview--section">
  <ng-container
    *ngTemplateOutlet="
      previewContent || defaultPreviewContent;
      context: { items: control?.value, previewClass: previewClass, showThumbnail: showThumbnail }
    "></ng-container>
</div>

<!-- DRAG FILE INTO SECTION-->
<ng-template #uploadContent>
  <div class="upload">
    <i class="fas fa-cloud-upload-alt"></i>
    <p [innerHTML]="label"></p>
    @if (placeholder) {
      <span class="placeholder">{{ placeholder }}</span>
    }
  </div>
</ng-template>

<ng-template #defaultPreviewContent>
  <app-file-preview
    [items]="control?.value"
    [styleClass]="previewClass"
    [deletable]="true"
    [showThumbnail]="showThumbnail"
    (cardClick)="cardClick?.emit($event)"
    (removeClick)="removeFile($event)">
  </app-file-preview>
</ng-template>

@if (control) {
  <cap-validator [control]="control" [customName]="label"></cap-validator>
}
