@if (items) {
  <div class="preview row">
    @for (uploaderItem of items; track uploaderItem) {
      <div [classList]="styleClass">
        @if (uploaderItem?.file || uploaderItem; as item) {
          <div class="file" (click)="cardClick.emit(item[nameProperty])">
            <div class="file__preview" [ngClass]="{ 'file__preview--centered': !showThumbnail }">
              @if (showThumbnail) {
                @if (showThumbnail) {
                  <img
                    [src]="imagePathProperty ? item[imagePathProperty] : (item | createObjectUrl)"
                    [alt]="item[nameProperty]" />
                }
              }
              @if (!showThumbnail) {
                <i class="far fa-file"></i>
              }
            </div>
            <p class="file__name">{{ item[nameProperty] }}</p>
            @if (deletable) {
              <i (click)="handleRemoveIconClick($event, item[nameProperty])" class="fas fa-trash-alt file--remove"></i>
            }
          </div>
        }
      </div>
    }
  </div>
}
