import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Harvest } from '@core/models/harvest.model';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { IndexDbHarvest } from '@core/indexDb/model/indexDb-harvest.model';

@Injectable({
  providedIn: 'root',
})
export class HarvestService extends ApiService<Harvest> {
  protected endpoint = 'harvest';

  private selectedHarvests = new BehaviorSubject<string[]>(null);

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public createOffline(data: any): Observable<any> {
    if (Array.isArray(data)) {
      return from(IndexDbHarvest.query().bulkAdd(data));
    } else {
      return from(IndexDbHarvest.query().add(data));
    }
  }

  public getTotalAmount(): Observable<number> {
    return from(IndexDbHarvest.query().count());
  }

  public removeOffline(data: any): Observable<any> {
    if (Array.isArray(data)) {
      return from(IndexDbHarvest.query().bulkDelete(data));
    } else {
      return from(IndexDbHarvest.query().delete(data));
    }
  }

  public getStoredHarvests(): Observable<IndexDbHarvest[]> {
    return from(IndexDbHarvest.query().toArray());
  }

  public deleteIntake(id: string): Observable<any> {
    return this.apiHttp.delete(`/${this.endpoint}/intake/${id}`);
  }

  public setSorting(packageId: string, data: any): Observable<any> {
    const url = `/${this.endpoint}/sort/${packageId}`;

    return this.apiHttp.put(url, data);
  }

  public getTransactionHarvestIntake(transactionId: string, options?: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/intake/all/transaction/${transactionId}`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getAllHarvestIntake(packageId: string, options?: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/intake/all/${packageId}`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public setHarvestIntakeAmount(harvestId: string, data: any, options?: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/intake/${harvestId}`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.put(url, data);
  }

  public setAllHarvestIntakeAmount(data: any, options?: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/intake/all/`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public getAllHarvestGrading(packageId: string, options?: ListOptions): Observable<Harvest> {
    let url = `/${this.endpoint}/sort/all/${packageId}`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public setAllHarvestGrading(data: any, options?: ListOptions): Observable<any> {
    let url = `/${this.endpoint}/sort/all/`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public move(
    data: { sorting_station_id: string; harvests: { package_id: string }[] },
    options?: ListOptions,
  ): Observable<any> {
    let url = `/${this.endpoint}/move`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public getSelectedHarvests(): Observable<string[]> {
    return this.selectedHarvests.asObservable();
  }

  public setSelectedHarvests(harvests: string[]): void {
    this.selectedHarvests.next(harvests);
  }
}
