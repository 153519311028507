import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  template: ` <div class="alert mrp-alert" role="alert" [ngClass]="styleClass">
    <h4 class="alert-heading mb-2 ut-primary-text"><i class="mr-2" [ngClass]="iconClass"></i> {{ title }}</h4>

    <p class="alert-content">{{ message }}</p>
  </div>`,
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input()
  public title: string;

  @Input()
  public message: string;

  @Input()
  public styleClass = '';

  @Input()
  public iconClass = 'fas fa-exclamation-circle';
}
